<template>
  <v-dialog
    v-model="isOpen"
    @click:outside="$emit('onClose')"
    persistent
    fullscreen
  >
    <div style="position: fixed; z-index: 10; height: 70px;">
      <div style="display: flex;" class="menu-preview">
        <div class="px-3">
          <a @click="actionShareDocument" style="color: #fff;" href="#">
            Partager
            <v-icon dark> mdi-share-variant </v-icon></a
          >
        </div>
        <div class="px-3">
          <a style="color: #fff;" :href="file.CheminDocument" target="_blank">
            Telecharger
            <v-icon dark> mdi-cloud-download-outline </v-icon></a
          >
        </div>
        <div class="px-3" style="margin-left: auto;">
          <a style="color: #fff;" @click.prevent="$emit('onClose')" href="#">
            Fermer
            <v-icon dark> mdi-close </v-icon></a
          >
        </div>
      </div>
      <div v-if="mounted && fileType == 'pdf'">
        <!-- {{currentPage}} / {{numPages}} -->
      </div>
    </div>
    <div
      class="modal__content"
      style="background: rgba(180, 180, 180, 1); height: 100%;"
    >
      <v-row class="px-4 h-100">
        <v-col v-if="!mounted" class="text-center">
          <v-progress-circular
            indeterminate
            color="indigo darken-4"
          ></v-progress-circular>
        </v-col>

        <v-col v-else>
          <div v-if="fileType == 'pdf' && numPages">
            <div style="margin-top: 80px;">
              <pdf
                v-for="i in numPages"
                :key="i"
                :src="src"
                :page="i"
                :id="'pdf_' + i"
                style="display: block; margin: 20px auto; max-width: 80%;"
              ></pdf>
              <!-- <pdf
                                    :src="file.CheminDocument"
                                    @num-pages="pageCount = $event"
                                    @page-loaded="currentPage = $event"
                                ></pdf> -->
            </div>
          </div>
          <div v-else style="margin-top: 80px;">
            <v-img
              style="margin: 0 auto;"
              max-width="90%"
              :src="file.CheminDocument"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </div>
    <input type="hidden" ref="copy" :value="file.CheminDocument" />
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";

import pdf from "vue-pdf";
export default {
  components: { pdf },

  data: () => ({
    //documents: [],
    currentPage: 0,
    numPages: null,
    src: null,
    mounted: false,
  }),

  async created() {
    this.mounted = true;
    if (this.fileType == "pdf") {
      this.loadingTask = pdf.createLoadingTask(
        `${this.file.CheminDocument}?cacheblock=true`
      );
    }
  },
  mounted() {
    if (this.fileType == "pdf") {
      this.src = this.loadingTask;
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    }
  },

  computed: {
    documentType() {
      return this.context.documentType;
    },

    file() {
      return this.context.document;
    },

    fileType() {
      return this.file.CheminDocument.split(".").pop();
    },
  },

  methods: {
    actionShareDocument() {
      let input = this.$refs.copy;
      input.setAttribute("type", "text");
      input.select();
      let newValue = [];
      console.log("input.value :>> ", input.value);
      if (input.value.includes("/upload.myffme.fr/")) {
        newValue = input.value.split("/upload.myffme.fr/");
        console.log(`${process.env.VUE_APP_SHARE_URL_DEV}${newValue[1]}`);
        navigator.clipboard.writeText(
          `${process.env.VUE_APP_SHARE_URL_DEV}${newValue[1]}`
        );
      } else if (input.value.includes("/upload.myffme.fr/")) {
        newValue = input.value.split("/uploads.myffme.fr/");
        console.log(`${process.env.VUE_APP_SHARE_URL_PROD}${newValue[1]}`);
        navigator.clipboard.writeText(
          `${process.env.VUE_APP_SHARE_URL_PROD}${newValue[1]}`
        );
      } else {
        newValue = input.value.split("/uploadstaging.myffme.fr/");
        console.log(`${process.env.VUE_APP_SHARE_URL_STAGING}${newValue[1]}`);
        navigator.clipboard.writeText(
          `${process.env.VUE_APP_SHARE_URL_STAGING}${newValue[1]}`
        );
      }

      success_notification("Le lien a été copié dans le presse papier");
      input.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.modal__content {
  overflow-x: hidden;
}
</style>
<style scoped>
hr {
  margin: 0;
  padding: 0;
  width: 100%;
}

.menu-preview {
  background: #002755;
  padding: 20px;
}
</style>
