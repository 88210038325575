<template>
  <div>
    <v-card max-width="344" outlined>
      <v-list-item v-if="user" three-line>
        <avatar
          :userData="user.UTI_Utilisateur"
          :showAvatar="showAvatar"
          size="56"
        />

        <v-list-item-content class="ml-4">
          <div>{{ userName }}</div>
          <div v-if="userFonction">{{ userFonction }}</div>
        </v-list-item-content>
      </v-list-item>

      <v-list-item three-line v-else>
        <v-list-item-content class="">
          <div>Aucun utilisateur sélectionné</div>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <div class="mt-4">
      <v-btn
        :disabled="!context.hasCodeFederal || disabled"
        @click="userModalIsOpen = true"
        class="btn btn-primary"
        tile
        >Sélectionner un membre</v-btn
      >
    </div>

    <ModalUsers
      :isOpen="userModalIsOpen"
      :context="{ ...context, onlyCurrentStructure: true }"
      @onClose="userModalIsOpen = false"
      @onUserSelected="userSelected"
    ></ModalUsers>
  </div>
</template>

<script>
import ModalUsers from "@/components/utils/modals/users";
import Avatar from "@/components/Avatar.vue";

export default {
  data: () => ({
    userModalIsOpen: false,
  }),

  components: { ModalUsers, Avatar },

  created() {},

  computed: {
    userName() {
      return (
        this.user.UTI_Utilisateur.CT_Nom +
        " " +
        this.user.UTI_Utilisateur.CT_Prenom
      );
    },

    userFonction() {
      if (this.user.FonctionUtilisateur) {
        return (
          this.user.FonctionUtilisateur.NomFonction +
          " " +
          this.user.DescriptionFonction
        );
      }
      return "";
    },
  },

  methods: {
    userSelected(user) {
      this.$emit("userSelected", user);
      this.userModalIsOpen = false;
    },
  },

  props: {
    user: {
      type: [Object, Number],
      default: null,
    },

    context: {
      type: Object,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    showAvatar: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
