<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    @click:outside="onClose"
    persistent
    width="80vw"
  >
    <div v-if="!loading" class="ffme_dialog dialog__address">
      <v-tabs v-model="tab" value="slug" background-color="blue-dark" dark grow>
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="item in items"
          :max-width="item.width"
          @click="changeTabWidth(item.width)"
          :key="item.tab"
          >{{ item.tab }}
        </v-tab>

        <v-icon class="close-icon" @click="onClose">mdi-close</v-icon>
      </v-tabs>

      <div class="modal__content" :key="refresh">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab">
            <v-card flat>
              <component
                :ref="item.componentName"
                :is="item.componentName"
                :adressToUpdate="adressToUpdate"
                :adress="selectedAdress"
                :context="context"
                @onSelected="onItemSelected"
                @onAdded="onItemAdded"
                @onEdited="onUpdated"
                @onEdit="goToEditTab"
                @onCardAddressUpdate="setAdresse()"
                :affiliation="affiliation"
              >
              </component>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import addAdresse from "@/components/utils/modals/addAdresse.vue";
import editAdresse from "@/components/utils/modals/editAdresse.vue";
import listAdresse from "@/components/utils/modals/listAdresse.vue";
import { mapActions } from "vuex";

import { sleep } from "@/plugins/utils";

export default {
  components: { addAdresse, editAdresse, listAdresse },
  data() {
    return {
      tab: null,
      loading: true,
      adressToUpdate: null,
      selectedAdress: null,
      items: [
        // {
        //   tab: "Liste des adresses",
        //   componentName: "listAdresse",
        //   width: "1400px",
        // },
        {
          tab: "Ajouter une adresse",
          componentName: "addAdresse",
          width: "1200px",
        },
        {
          tab: "Editer une adresse",
          componentName: "editAdresse",
          width: "1200px",
        },
      ],
      refresh: 0,
      maxWidth: "1400px",
    };
  },

  async created() {
    this.tab = this.tabInit;
    if (this.item) {
      this.selectedAdress = this.item;
    }
    // if (this.selection) {
    //   this.tab = 1;
    // }
    if (this.context.ID_Structure) {
      await this.getStructureAdresses(this.context);
    } else {
      console.error(
        "You need to pass an ID_Structure or ID_User to adresse modal"
      );
    }

    this.loading = false;
    return;
  },

  methods: {
    ...mapActions("structure", ["getStructureAdresses"]),

    onClose() {
      this.tab = 0;
      this.$emit("onClose");
    },

    onItemSelected(item) {
      this.$emit("onSelected", item);
    },

    onItemAdded(item) {
      this.$emit("onAdded", item);
      this.$emit("onClose");
    },

    async onUpdated(item) {
      this.$refs.listAdresse[0].setData();
      this.$emit("onUpdated", item);
    },
    async goToEditTab(adress) {
      console.log("this.$refs:", this.$refs);
      this.tab = 2;
      this.adressToUpdate = adress;
      await sleep(100);
      this.$refs.editAdresse[0].setData();
    },
    changeTabWidth(width) {
      this.maxWidth = width;
    },
    setAdresse(address) {
      this.$emit("onCardAddressUpdate", address);
    },
  },
  watch: {
    tab() {
      console.log(`tab`, this.tab);
    },
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: null,
    },

    selection: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },
    tabInit: {
      type: Number,
      default: 0,
    },
    affiliation: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  background: #fff !important;
}
/*.dialog__address {*/
/*   .v-tabs-bar {*/
/*      .v-tab {*/
/*         background: #ffffff96;*/
/*      }*/
/*      .v-tab--active {*/
/*         background: transparent;*/
/*      }*/
/*   }*/
/*}*/
</style>
