<template>
  <div v-if="mounted">
    <div id="test" style="padding: 40px 20px">
      <v-row>
        <v-col cols="6">
          <ValidateForm @validated="submit" :loading="loading">
            <selectCountry
              label="Pays"
              v-model="currentCountrySelected"
              @onChange="changeSelectedPays"
            />

            <div
              :class="{
                'div__content-disabled __pays': !currentCountrySelected,
              }"
            >
              <InputText
                v-model="currentAdresse.NomAdresse"
                label="Nom adresse"
                rules="required"
              ></InputText>

              <InputText
                v-model="currentAdresse.NomHebergeur"
                label="Nom hébergeur"
              ></InputText>

              <AdresseAutoComplete
                :item="currentAdresse.Adresse1"
                rules="min:6|max:50"
                label="Adresse"
                ref="adresseAuto"
                :key="refresh"
                @onSelected="adresseSelected"
                @onLocationSelected="locationSelected"
                @onChange ="writeValue"
                :restrictCountry="restrictCountry"
              ></AdresseAutoComplete>

              <InputText
                v-model="currentAdresse.Adresse2"
                label="Adresse complément"
              ></InputText>

              <InputText
                v-model="currentAdresse.Ville"
                label="Ville"
                rules="required"
              ></InputText>

              <InputText
                v-model="currentAdresse.CodePostal"
                label="Code postal"
                rules="required"
              ></InputText>
            </div>
          </ValidateForm>
        </v-col>
        <v-col
          cols="6"
          class="mapContainer"
          v-show="
            (currentCountrySelected && showMap) || currentAdresse.Adresse1
          "
        >
          <Map
            :center="localisation"
            @set-location="modifyLocation"
            key="editAdresse"
          />
        </v-col>
      </v-row>

      <v-card-actions class="px-0">
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="submit"
          :loading="loading"
          class="btn btn-primary"
          >Mettre à jour</v-btn
        >
        <v-btn
          @click="selectAdresse"
          :disabled="!adresses.length"
          class="btn btn-primary ml-4"
          >Sélectionner</v-btn
        >
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";

import AdresseAutoComplete from "@/components/utils/adresseAutocomplete.vue";
import { sleep } from "@/plugins/utils";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import selectCountry from "@/components/utils/select__countries.vue";
import { bus } from "@/main";
import Map from "@/components/utils/Map";

export default {
  components: {
    ValidateForm,
    InputText,
    AdresseAutoComplete,
    selectCountry,
    Map,
  },

  data: () => ({
    adresse: {
      NomAdresse: "",
      Adresse1: "",
      Adresse2: "",
      Ville: "",
      CodePostal: "",
      adresseAutocomplete: "",
      ID_Pays: "",
      NomHebergeur: "",
      CoordonneesGPS: "",
      refresh: 0,
    },
    loading: false,
    mounted: false,
    currentAdresseSelected: null,
    currentCountrySelected: null,
    isSelectable: false,
    currentAdresse: null,
    adresses: [],
    pays: null,
    restrictCountry: "FR",
    localisation: [48.852969, 2.349903],
    showMap: false,
  }),

  async created() {
    this.pays = await this.getListPays();
    //console.log(this.pays)

    await this.setData();
    this.mounted = true;
    console.log(`this.adress==>>`, this.adress);
    await sleep(100);
    //this.$refs.adresseAuto.setRestriction(this.restrictCountry)
  },

  computed: {
    ...mapGetters("structure", ["currentStructure"]),
  },

  methods: {
    ...mapActions("structure", [
      "insertStructureAddress",
      "getStructureAdresses",
    ]),
    ...mapActions("basics", ["getListPays"]),

    async selectAdresse() {
      // if (!this.dataEquals()) {
      //   const answer = window.confirm(
      //     "L'adresse selectionné n'a pas été enregistrer. Enregistrer et selectionner ?"
      //   );
      //   if (answer) {
      //     await this.submit();
      //     this.$emit("onSelected", this.currentAdresse);
      //     return;
      //   }
      // } else {
      this.$emit("onSelected", this.currentAdresse);
      // }
    },
    async locationSelected(location) {
      let lat = location.lat();
      let lng = location.lng();
      this.currentAdresse.CoordonneesGPS = JSON.stringify({
        lat: lat,
        lng: lng,
      });
      this.localisation = [lat, lng];
    },
    modifyLocation(newLocation) {
      this.currentAdresse.CoordonneesGPS = JSON.stringify({
        lat: newLocation[0],
        lng: newLocation[1],
      });
    },

    // dataEquals() {
    //   if (typeof this.currentAdresseSelected === "number") {
    //     this.currentAdresseSelected = this.adresses.find(
    //       adr => adr.id === this.currentAdresseSelected
    //     );
    //   }
    //   if (
    //     JSON.stringify(this.currentAdresse) !==
    //     JSON.stringify(this.currentAdresseSelected)
    //   ) {
    //     return false;
    //   }
    //   return true;
    // },

    changeSelectedAdresse(item) {
      let newAdr;
      this.adresses.forEach((adr) => {
        if (adr.id === item) {
          newAdr = adr;
        }
      });

      if (newAdr.ID_Pays) {
        this.currentCountrySelected = this.pays.find(
          (p) => p.id === newAdr.ID_Pays
        );
      } else {
        this.currentCountrySelected = {};
      }
      //  let test = this.adresses.find(adr => adr.id = item)
      this.currentAdresseSelected = newAdr;
      this.currentAdresse = { ...newAdr };
    },

    changeSelectedPays(item) {
      this.restrictCountry = item.CodePays;
      this.$refs.adresseAuto.setRestriction(this.restrictCountry);
      this.resetForm();
      this.currentAdresse.ID_Pays = item.id;
    },

    async setData() {
      let adress = this.adressToUpdate || this.adress;

      // console.log("adresse setData *********************** ", adress);
      if (adress) {
        adress.Adresse1 = adress.Adresse1.split(" - ")[0];
        this.currentAdresse = adress;
        // console.log("this.currentAdresse !!!!", this.currentAdresses);
        if (adress.ID_Pays) {
          this.currentCountrySelected = this.pays.find(
            (p) => p.id === adress.ID_Pays
          );
        }
      } else {
        if (this.adresses[0]) {
          this.currentAdresse = this.adresses[0];
          this.currentCountrySelected = this.pays.find(
            (p) => p.id === this.currentAdresse.ID_Pays
          );
        }
      }

      if (this.currentAdresse) {
        this.currentAdresse = { ...this.currentAdresse };
      } else {
        this.currentAdresse = {};
      }
      this.refresh++;
      console.log(`this.currentAdresse===>>`, this.currentAdresse);
      const coordonne =
        this.currentAdresse.CoordonneesGPS &&
        JSON.parse(this.currentAdresse?.CoordonneesGPS);
      this.localisation = coordonne
        ? [coordonne.lat, coordonne.lng]
        : this.localisation;

      return;
    },

    async adresseSelected(place) {
      // console.log("place ************", place);
      if (place[6]) {
        this.$refs.adresseAuto.currentValue =
          place[0].long_name + " " + place[1].long_name;
        this.currentAdresse.Adresse1 =
          place[0].long_name + " " + place[1].long_name;
        this.currentAdresse.Ville = place[2].long_name;
        this.currentAdresse.CodePostal = place[6].long_name;
      }
      else {
        this.$refs.adresseAuto.currentValue =
          place[0].long_name + " - " + place[4].long_name;
        this.currentAdresse.Adresse1 =
          place[0].long_name + " " + place[4].long_name;
        this.currentAdresse.Ville = place[1].long_name;
      }
    },

    writeValue(place){
      if(place){
        this.currentAdresse.Adresse1 = place;
      }
    },

    resetForm() {
      this.$refs.adresseAuto.currentValue = "";
      this.currentAdresse.NomAdresse = "";
      this.currentAdresse.NomHebergeur = "";
      this.currentAdresse.Adresse1 = "";
      this.currentAdresse.Adresse2 = "";
      this.currentAdresse.Ville = "";
      this.currentAdresse.CodePostal = "";
      this.currentAdresse.adresseAutocomplete = "";
      this.currentAdresse.ID_Pays = "";
    },

    async submit() {
      //this.loading = true
      try {
        let adresse = this.currentAdresse;
        // console.log("adresse", adresse);
        delete adresse.__typename;
        adresse.CodeInsee = await this.getCodeInsee(adresse.CodePostal);
        delete adresse.adresseAutocomplete;
        await this.insertStructureAddress({
          adresse: adresse,
          ID_Structure: this.context.ID_Structure,
        });
        success_notification("Informations modifiées");
        this.adresses = [...(await this.getStructureAdresses(this.context))];
        bus.$emit("onStructureAdresseUpdated");
        this.$emit("onEdited", "test");
        return;
      } catch (e) {
        console.log(e);
        this.loading = false;
        error_notification("Oups, une erreur est survenue...");
        return;
      }
    },

    async getCodeInsee(cp) {
      return await this.axios
        .get("https://geo.api.gouv.fr/communes?codePostal=" + cp)
        .then((response) => {
          if (response.data[0]) {
            return response.data[0].code;
          }
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    adressToUpdate: function() {
      this.setData();
    },
  },

  props: {
    adress: {
      type: Object,
      default: null,
    },
    adressToUpdate: {
      type: Object,
      default: null,
    },

    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.address-select-container {
  height: 20px;
  padding: 0px 20px;
  margin-top: 20px;

  .v-input__slot {
    max-width: 100% !important;
    padding: 3px;

    .v-select__selections {
      padding: 6px !important;
    }
  }
}
.mapContainer {
  height: 550px;
}
</style>
