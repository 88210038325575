<template>
  <v-col cols="12 py-0">
    <v-row>
      <v-col
        cols="12"
        md="7"
        class="py-0"
        style="display: flex; align-items: center;"
      >
        <div class="px-2 py-2">
          <span class="font-weight-bold" style="font-size: 0.9rem;">
            {{ documentTypeLabel }}
            {{ isDocumentRequired ? '*' : ''}}
          </span>
        </div>

        <div v-if="isAffiliation && document.type === INTERNAL_RULES || isAffiliation && document.type === STATUS" style="font-size: 12px; font-weight: 300;">
          A transmettre en cas de mise à jour depuis la saison dernière
        </div>
      </v-col>
      <v-col cols="12" md="5" class="text-right">
        <span style="font-size: 13px;">
          {{
            $moment(document.createdAt).format("DD/MM/YYYY")
          }}</span
        >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="ml-2"
              :disabled="!hasCodeFederal"
              @click="openPreview"
              fab
              dark
              x-small
              outlined
              :color="!!document.file ? 'indigo darken-4' : 'grey lighten-1'"
            >
              <v-icon>mdi-image</v-icon>
            </v-btn>
          </template>
          <span>Afficher le document</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="ml-2 mr-2"
              :disabled="!hasCodeFederal"
              @click.prevent="modalHistoryIsOpen = true"
              fab
              dark
              x-small
              outlined
              color="indigo darken-4"
            >
              <v-icon>mdi-history</v-icon>
              <modalHistoryDocument
                @onClose="modalHistoryIsOpen = false"
                :isOpen="modalHistoryIsOpen"
                v-if="modalHistoryIsOpen"
                :context="{
                  ...context,
                  document: document,
                  documentType: document.type,
                }"
                :fileType="fileType"
              ></modalHistoryDocument>
            </v-btn>
          </template>
          <span>Afficher l'historique</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="!hasCodeFederal"
              @click="modalUploadDocumentIsOpen = true"
              fab
              dark
              outlined
              x-small
              color="indigo darken-4"
            >
              <v-icon dark>mdi-plus</v-icon>
              <modalUploadDocument
                @onClose="modalUploadDocumentIsOpen = false"
                :isOpen="modalUploadDocumentIsOpen"
                :dateneeded="false"
                v-if="modalUploadDocumentIsOpen"
                :context="{
                  ...context,
                  document: document,
                  documentType: document.type,
                }"
                @insertDocumentNewItem="insertFileInDB"
              ></modalUploadDocument>
            </v-btn>
          </template>
          <span>Téléverser un nouveau document</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import modalHistoryDocument from "@/components/utils/modals/Documents/Document__HistoryNewDocumentModal.vue";
import modalUploadDocument from "@/components/utils/modals/Documents/Document__UploadNewDocument.vue";
import {
  structureDocumentTypeLabel,
  GENERAL_MEETING_REPORT,
  SUPERVISOR_DIPLOMA,
  INSURANCE_CERTIFICATE,
  PROFESSIONAL_CARD,
} from "@/constants/structureDocumentType";


export default {
  components: {
    modalHistoryDocument,
    modalUploadDocument,
  },

  data: () => ({
    modalHistoryIsOpen: false,
    modalUploadDocumentIsOpen: false,
    modalPreviewIsOpen: false,
  }),

  computed: {
    ...mapGetters("structure", ["hasCodeFederal"]),
    DocumentTitle() {
      return this.document.NomDocument;
    },

    documentTypeLabel() {
      return structureDocumentTypeLabel[this.document.type];
    },

    isDocumentRequired() {
      return [GENERAL_MEETING_REPORT, SUPERVISOR_DIPLOMA, INSURANCE_CERTIFICATE, PROFESSIONAL_CARD].includes(this.document.type);
    },

    hasDocument() {
      return this.document.CheminDocument && this.document.NomDocument;
    },
  },

  methods: {
    ...mapActions("basics", ["getListTypesDocument", "insertDocument"]),
    ...mapActions("structure", [
      "updateAffiliationDocument",
      "getDocumentStructureByType",
    ]),

    openPreview() {
      if (!this.document.file) return false;

      window.open(process.env.VUE_APP_FFME_BACK_URL + '/api/files/' + this.fileType + '/' + this.document.id);

      return null;
    },

    insertFileInDB(file) {
      this.$emit("insertDocument", file);
    },
  },

  props: {
    document: {
      type: Object,
    },
    context: {
      type: Object,
    },
    isAffiliation: {
      type: Boolean,
      default: false,
    },
    ID_DocCompteRenduAg: {
      type: Number,
      default: null,
    },
    fileType: {
      type: String,
      default: null,
    },
  },
};
</script>
