<template>
  <v-col cols="12 py-0">
    <v-row>
      <v-col v-if="isNewDoc">
        <DocumentNewItem
          :document="document.data"
          :context="context"
          :isAffiliation="isAffiliation"
          @onUploaded="documentUploaded"
          @insertDocument="insertStructureDocument"
          :ID_DocCompteRenduAg="ID_DocCompteRenduAg"
          fileType="structure_document"
        />
      </v-col>
      <v-col v-else>
        <DocumentItem
          :document="document.data"
          :context="context"
          :isAffiliation="isAffiliation"
          @onUploaded="documentUploaded"
          @insertDocument="insertStructureDocument"
          :ID_DocCompteRenduAg="ID_DocCompteRenduAg"
          fileType="structure_document"
        />
      </v-col>
    </v-row>
    <hr />
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import DocumentItem from "@/components/GestionDesDocuments/GestionDesDocuments__Item.vue";
import DocumentNewItem from "@/components/GestionDesDocuments/GestionDesDocuments__NewItem.vue";
import { uploadStructureDocument } from "../../plugins/structureService";
import { docDocumentIdDocToStructureDocumentType } from "@/constants/structureDocumentType";

export default {
  components: {
    DocumentItem,
    DocumentNewItem,
  },

  data: () => ({
    modalUploadDocumentIsOpen: false,
  }),

  computed: {
    ...mapGetters("structure", ["hasCodeFederal"]),
    DocumentTitle() {
      return this.document.NomDocument;
    },

    isNewDoc() {
      return this.document.isNewDoc;
    },

    hasDocument() {
      return this.document.CheminDocument && this.document.NomDocument;
    },
  },

  methods: {
    ...mapActions("basics", ["getListTypesDocument", "insertDocument"]),
    ...mapActions("structure", [
      "updateAffiliationDocument",
      "getDocumentStructureByType",
    ]),

    async insertStructureDocument(file) {
      let documentType = null;

      if(this.isNewDoc) {
        documentType = this.document.data.type;
      } else {
        documentType = docDocumentIdDocToStructureDocumentType[this.document.data.ID_Type_Document];
      }

      await uploadStructureDocument(file, this.context.ID_Structure, this.context.ID_Saison, documentType)
        .then(() => {
          this.modalUploadDocumentIsOpen = false;
          success_notification("Ficher enregistré");
          window.location.reload();
        }).catch(() => {
          error_notification("Oups, une erreur est survenue lors du téléchargement du document...");
        });

      return null;
    },
  },

  props: {
    document: {
      type: Object,
    },

    context: {
      type: Object,
    },
    isAffiliation: {
      type: Boolean,
      default: false,
    },
    ID_DocCompteRenduAg: {
      type: Number,
      default: null,
    },
    documentUploaded: {
      type: Function,
    },
  },
};
</script>
