<template>
  <v-dialog
    v-model="isOpen"
    @click:outside="$emit('onClose')"
    persistent
    max-width="800px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display: flex; flex-direction: column;">
        <span style="font-size: 1.2rem;"> Gestion des documents </span>
      </v-tab>
      <v-icon class="close-icon" @click="$emit('onClose')">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content">
      <v-tabs-items>
        <v-row class="p-4">
          <v-col cols="12">
            <div><span class="font-weight-bold">Type de document:</span></div>
            <div class="px-2 py-3 border mt-2">
              {{ documentType.NomTypeDocument }}
            </div>

            <v-row no-gutters class="my-4">
              <v-col cols="12" class="inner-card-preview" style="">
                <div class="text-center inner-upload-preview" style="">
                  <div>
                    <v-icon right dark color="indigo darken-4">mdi-file</v-icon>
                  </div>

                  <div v-if="fileURL">
                    {{ formatedFileName }}.{{ getFileExtension() }}
                  </div>
                  <div v-else>
                    fichiers autorisés: jpg, jpeg, png ou pdf | 5 MO max
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="mt-4">
                <v-row>
                  <v-col cols="12" md="6" class="d-flex align-center">
                    <input
                      style="display: none;"
                      accept=".jpg,.jpeg,.png,.pdf"
                      @change="processFile"
                      type="file"
                      ref="fileInput"
                    />
                    <v-btn
                      class="btn btn-primary"
                      :disabled="loading"
                      @click="triggerWindowUpload"
                    >
                      Téléverser un document
                      <!-- <v-icon right dark>mdi-cloud-upload</v-icon> -->
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="dateneeded"
                    cols="12"
                    md="6"
                    class="d-flex flex-column input-date"
                  >
                    <label class="m-0" for="" style="font-size: 0.9rem;"
                      >Date de signature</label
                    >
                    <DatePicker
                      rules="required"
                      v-model="documentDate"
                      :disabled="loading"
                    ></DatePicker>
                  </v-col>
                  <v-col
                    v-else
                    cols="12"
                    md="6"
                    class="d-flex flex-column input-date"
                  >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="btn-upload">
                <v-btn
                  :disabled="uploadIsDisabled"
                  :loading="loading"
                  @click="uploadDocument"
                  class="btn btn-primary"
                  >Enregistrer</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { error_notification } from "@/plugins/notifications";
import { string_to_slug } from "@/plugins/utils";
import DatePicker from "@/components/utils/datePicker";
import { mapGetters } from "vuex";
export default {
  components: { DatePicker },

  data: () => ({
    file: {},
    fileURL: null,
    loading: false,
    documentDate: null,
  }),

  async created() {},

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),

    documentType() {
      return this.context.documentType;
    },

    formatedFileName() {
      // return this.context.ID_Saison+"_"+string_to_slug(this.documentType.NomTypeDocument)
      return string_to_slug(this.documentType.NomTypeDocument);
    },

    uploadIsDisabled() {
      if (!this.file.extension && !this.fileURL) return true;
      if (this.dateneeded)
        if (this.documentDate) return true;
        else if (!this.dateneeded) if (!this.documentDate) return true;
      return false;
    },
  },

  methods: {
    // Simulate the click on the hidenn input file
    triggerWindowUpload() {
      this.$refs.fileInput.click();
    },

    // convert the input value into file object and check his validity
    async processFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.file = files[0];

      // Check size and file type
      if (!(await this.checkFile())) return false;

      await this.readFile();

      return true;
    },

    // Chek file size and extesion
    async checkFile() {
      if (!this.checkExtension()) {
        error_notification(
          "Les formats valides sont les suivants: jpg, jpeg, png et pdf."
        );
        return false;
      }

      if (!this.checkFileSize()) {
        error_notification("Le fichier est trop volumineux (5Mo max).");
        return false;
      }

      return true;
    },

    // convert file into a string for upload
    async readFile() {
      // Convert file into url transferable string
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fileURL = event.target.result;
          console.log("file ako ty", this.fileURL);
          return true;
        };
        reader.readAsDataURL(this.file);
      } else {
        error_notification("Désolé, une erreur est survenue.");
        console.log("FileReader API not supported");
      }
    },

    // Check file size 0-0
    checkFileSize() {
      const maxFileSize = 5_000_000; // == 5 MO
      if (this.file.size > maxFileSize) {
        return false;
      }
      return true;
    },

    // get file extension
    getFileExtension() {
      let fileExt = this.file.type.split("/");
      if (!fileExt[1]) return false;
      return fileExt[1];
    },

    // Check file extension
    checkExtension() {
      const allowedFileType = ["jpg", "jpeg", "png", "pdf"];

      let isValid = allowedFileType.find(
        (type) => type == this.getFileExtension()
      );
      if (isValid) return true;
      return false;
    },

    // Upload document and emit the respnse object for post processing
    async uploadDocument() {
      this.loading = true;
      let structureID = this.context.ID_Structure ? this.context.ID_Structure : this.currentStructureId;

      const hash =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      let payload = {
        image: this.fileURL,
        key: `${this.formatedFileName}_${
          structureID
        }_${hash}.${this.getFileExtension()}`, // IL s'agit du nom du fichier, je pense qu tu en ai besoin, mais je préferze le laisser pour le moment,
        ID_Structure: structureID,
      };
      console.log("payload ==========>", payload);

      this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload", payload,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
              }
            }
        )
        .then((response) => {
          console.log("response uploadDocument", response);
          if (
            response.data &&
            response.data.location &&
            response.status &&
            response.status === 200
          ) {
            let responseFile = {
              url: response.data.location,
              filename: this.formatedFileName + "." + this.getFileExtension(),
              date: this.documentDate,
            };
            console.log("responseFile uploadDocument", responseFile);
            this.$emit("onFileUploaded", responseFile);
            this.loading = false;
            return true;
          } else {
            error_notification();
            this.loading = false;
            console.log("Error on upload");
          }
        })
        .catch((error) => {
          this.loading = false;
          error_notification();
          console.log(error.toString());
        });
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    dateneeded: {
      type: Boolean,
      default: true,
    },
    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__content {
  overflow-x: hidden;
}

.inner-card-preview {
  height: 250px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.inner-upload-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.btn-upload {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}
</style>
