<template>
  <div>
    <div style="padding: 20px">
      <ValidateForm
        v-if="!loading"
        @validated="submit"
        :loading="loading"
        :showFieldObl="false"
      >
        <v-row>
          <v-col cols="6">
            <selectCountry
              label="Pays"
              v-model="currentCountrySelected"
              @onChange="changeSelectedPays"
            />

            <div
              :class="{
                'div__content-disabled __pays': !currentCountrySelected,
              }"
            >
              <InputText
                v-model="currentAdresse.NomAdresse"
                label="Nom adresse"
                rules="required"
              ></InputText>

              <InputText
                v-model="currentAdresse.NomHebergeur"
                label="Nom hébergeur"
                rules="required"
              ></InputText>

              <AdresseAutoComplete
                :item="currentAdresse.adresseAutocomplete"
                rules="min:6|max:50|required"
                label="Adresse"
                :minimum_characters="3"
                ref="adresseAuto"
                @onSelected="adresseSelected"
                @onLocationSelected="locationSelected"
                @onChange="writeValue"
                :restrictCountry="restrictCountry"
              ></AdresseAutoComplete>

              <InputText
                v-model="currentAdresse.Adresse2"
                label="Adresse complément"
              ></InputText>

              <InputText
                v-model="currentAdresse.Ville"
                label="Ville"
                rules="required"
              ></InputText>

              <InputText
                v-model="currentAdresse.CodePostal"
                label="Code postal"
                rules="required"
              ></InputText>
            </div>
          </v-col>
          <v-col cols="6" class="mapContainer" v-if="showMap">
            <Map
              :center="localisation"
              @set-location="modifyLocation"
              key="mapAddAdresse"
            />
          </v-col>
        </v-row>
        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn type="submit" :loading="loading" class="btn btn-primary"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </ValidateForm>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";
import selectCountry from "@/components/utils/select__countries.vue";
import AdresseAutoComplete from "@/components/utils/adresseAutocomplete.vue";
import Map from "@/components/utils/Map";
import "leaflet/dist/leaflet.css";

import { bus } from "@/main";

import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: {
    ValidateForm,
    InputText,
    AdresseAutoComplete,
    selectCountry,
    Map,
  },

  data: () => ({
    adresse: {
      NomAdresse: "",
      Adresse1: "",
      Adresse2: "",
      Ville: "",
      CodePostal: "",
      adresseAutocomplete: "",
      ID_Pays: "",
      NomHebergeur: "",
    },
    loading: true,
    currentAdresse: null,
    currentCountrySelected: "",
    restrictCountry: "FR",
    localisation: [48.852969, 2.349903],
    showMap: false,
  }),

  async created() {
    this.pays = await this.getListPays();
    this.currentCountrySelected = this.pays.find((p) => p.CodePays == "FR");
    await this.setData();
    this.loading = false;
  },

  computed: {},

  methods: {
    ...mapActions("structure", [
      "insertStructureAddress",
      "updateStructureAdresseAssignement",
    ]),
    ...mapActions("basics", ["getListPays"]),
    modifyLocation() {
      console.log(`es`);
    },
    async setData() {
      this.currentAdresse = { ...this.adresse };
      this.currentAdresse.ID_Pays = this.currentCountrySelected.id;
      // console.log(this.currentAdresse);
      return;
    },
    async locationSelected(location) {
      this.showMap = true;
      let lat = location.lat();
      let lng = location.lng();
      this.currentAdresse.CoordonneesGPS = JSON.stringify({
        lat: lat,
        lng: lng,
      });
      this.localisation = [lat, lng];
    },
    async adresseSelected(place) {
      // console.log("place ************", place);
      if (place[6]) {
        this.$refs.adresseAuto.currentValue =
          place[0].long_name + " " + place[1].long_name;
        this.currentAdresse.Adresse1 =
          place[0].long_name + " " + place[1].long_name;
        this.currentAdresse.Ville = place[2].long_name;
        this.currentAdresse.CodePostal = place[6].long_name;
      } else {
        this.$refs.adresseAuto.currentValue =
          place[0].long_name + " - " + place[4].long_name;
        this.currentAdresse.Adresse1 =
          place[0].long_name + " " + place[4].long_name;
        this.currentAdresse.Ville = place[1].long_name;
      }
    },

    writeValue(place) {
      if (place) {
        this.currentAdresse.Adresse1 = place;
      }
    },

    changeSelectedPays(item) {
      this.restrictCountry = item.CodePays;
      this.$refs.adresseAuto.setRestriction(this.restrictCountry);
      this.currentAdresse.ID_Pays = item.id;
      this.resetForm();
    },

    resetForm() {
      this.$refs.adresseAuto.currentValue = "";
      this.currentAdresse.NomAdresse = "";
      this.currentAdresse.NomHebergeur = "";
      this.currentAdresse.Adresse1 = "";
      this.currentAdresse.Adresse2 = "";
      this.currentAdresse.Ville = "";
      this.currentAdresse.CodePostal = "";
      this.currentAdresse.adresseAutocomplete = "";
      this.currentAdresse.ID_Pays = "";
    },

    async getCodeInsee(cp) {
      return await this.axios
        .get("https://geo.api.gouv.fr/communes?codePostal=" + cp)
        .then((response) => {
          if (response.data[0]) {
            return response.data[0].code;
          }
          return;
        })
        .catch((error) => {
          // console.log(error);
        });
    },

    async submit() {
      this.loading = true;
      try {
        let adresse = { ...this.currentAdresse };
        delete adresse.adresseAutocomplete;
        adresse.ID_Structure = this.context.ID_Structure;
        adresse.CodeInsee = await this.getCodeInsee(adresse.CodePostal);
        let adressInserted = await this.insertStructureAddress({
          adresse: adresse,
          ID_Structure: this.context.ID_Structure,
        });
        if (this.affiliation) {
          let payload = {
            type: "correspondance",
            adresse: adressInserted.data.insert_ADR_Adresse.returning[0],
            ID_Structure: this.context.ID_Structure,
          };
          try {
            await this.updateStructureAdresseAssignement(payload);
            success_notification("Adresse assignée");
            this.$emit(
              "onCardAddressUpdate",
              adressInserted.data.insert_ADR_Adresse.returning[0]
            );
          } catch (e) {
            console.log(e);
            error_notification("Oups, une erreur est survenue...");
          }
        }
        bus.$emit("onStructureAdresseUpdated");
        this.loading = false;
        success_notification("Informations modifiées");
        this.$emit("onAdded");
      } catch (e) {
        this.loading = false;
        console.log(e);
        error_notification("Oups, une erreur est survenue...");
      }
    },
  },

  props: {
    context: {
      type: Object,
    },
    affiliation: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.mapContainer {
  height: 550px;
}
</style>
