var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.mounted)?_c('div',[_vm._v("Chargement ...")]):_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-alert',{attrs:{"border":"left","color":_vm.colorEtapeAffiliation,"dark":""}},[_vm._v(" "+_vm._s(_vm.currentEtapeAffiliation.NomEtapeAffiliation)+" ")])],1)],1),_c('ValidateForm',{attrs:{"loading":_vm.loading},on:{"validated":_vm.submit}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Correspondant")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('userAssign',{attrs:{"user":_vm.currentNomCorrespondant,"context":{
                ID_Structure: _vm.currentStructureId,
                ID_Saison: _vm.selectedSaison.id,
                hasCodeFederal: _vm.hasCodeFederal,
              }},on:{"userSelected":_vm.userSelectedNomCorrespondant}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Adresse correspondant")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('adressAssign',{attrs:{"adresse":_vm.currentAdresseCorrespondance,"context":{
                ID_Structure: _vm.currentStructureId,
                type: 'correspondance',
                hasCodeFederal: _vm.hasCodeFederal,
              }},on:{"onCardUpdate":_vm.adresseSelected}})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('inputText',{attrs:{"label":"N° SIRET","rules":"siret"},model:{value:(_vm.JUR_Siret),callback:function ($$v) {_vm.JUR_Siret=$$v},expression:"JUR_Siret"}}),_c('inputText',{attrs:{"label":"Agrément Jeunesse et sports"},model:{value:(_vm.JUR_AgrementJeunesseSports),callback:function ($$v) {_vm.JUR_AgrementJeunesseSports=$$v},expression:"JUR_AgrementJeunesseSports"}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Liste des dirigeants déclarés")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Président")]),_c('userAssign',{attrs:{"user":_vm.userPresident,"context":{
            ID_Structure: _vm.currentStructureId,
            ID_Saison: _vm.selectedSaison.id,
            hasCodeFederal: _vm.hasCodeFederal,
          }},on:{"userSelected":_vm.userFonctionPresidentSelected}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Secrétaire")]),_c('userAssign',{attrs:{"user":_vm.userSecretaire,"context":{
            ID_Structure: _vm.currentStructureId,
            ID_Saison: _vm.selectedSaison.id,
            hasCodeFederal: _vm.hasCodeFederal,
          }},on:{"userSelected":_vm.userFonctionSecretaireSelected}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Trésorier")]),_c('userAssign',{attrs:{"user":_vm.userTresorier,"context":{
            ID_Structure: _vm.currentStructureId,
            ID_Saison: _vm.selectedSaison.id,
            hasCodeFederal: _vm.hasCodeFederal,
          }},on:{"userSelected":_vm.userFonctionTresorierSelected}})],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.canSubmit && _vm.hasCodeFederal)?_c('v-btn',{staticClass:"btn btn-primary mt-5",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(" Enregistrer les modifications ")]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"btn btn-primary mt-5",attrs:{"type":"submit","disabled":true}},[_vm._v(" Enregistrer les modifications")])],1)]}}])},[_c('span',[_vm._v("Vous n'avez pas les droits nécessaire pour accéder à ce module. Merci de contacter un administrateur.")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }