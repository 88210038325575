<template>
  <v-row
    align-content="center"
    :no-gutters="label ? false : true"
    class="ffme__date-picker"
  >
    <v-col
      :md="3"
      v-if="label"
      :class="{ 'field__label-column': true, 'py-1': twoLines }"
    >
      <font-awesome-icon
        :icon="{ prefix: 'fab', iconName: icon }"
        class="mr-2"
        v-if="icon"
      />
      <label for="">{{ label }}{{ isRequired && !disabled ? "*" : "" }}</label>
    </v-col>

    <v-col
      :md="9"
      style="flex: 1;"
      :class="{ 'field__value-column ': true, 'pt-0 pb-1': twoLines }"
    >
      <validation-provider
        :rules="rules"
        v-slot="{ errors }"
        :name="getName"
        :ref="customRef"
        style="width: 100%; max-width: 415px;"
      >
        <label
          for=""
          style="
            font-size: 10px;
            margin-bottom: 0;
            position: absolute;
            top: -2px;
            left: 0;
          "
          >{{ placeholder }}</label
        >

        <inputText
          v-if="isSafari"
          ref="datepickerinput"
          v-model="displayedValue"
          type="text"
          :maxDate="maxInputDate"
          :minDate="minInputDate"
          placeholder="jj/mm/aaaa"
          @keydown="checkInput"
          @blur="checkDate"
          :disabled="disabled"
        ></inputText>

        <inputText
          v-else
          ref="datepickerinput"
          v-model="currentValue"
          type="date"
          :maxDate="maxInputDate"
          :minDate="minInputDate"
          :disabled="disabled"
        ></inputText>
        <div v-if="areErrors" style="margin-top: 10px;">
          <span
            class="form__error-message"
            v-for="(error, key) in errorsMessages"
            :key="key"
            >{{ error }}</span
          >
        </div>
        <span class="form__error-message" v-if="!errorDate">{{
          errors[0]
        }}</span>
        <span class="form__error-message" v-if="errorDate"
          >Merci de renseigner une date au format JJ/MM/AAAA.</span
        >
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import { translateDate } from "@/plugins/utils.js";
import inputText from "@/components/utils/input.vue";
import { bus } from "@/main";

export default {
  components: { inputText },

  data: () => ({
    menu: false,
    datePicker: "",
    customFormat: "DD/MM/YYYY",
    debug: true,
    dateInput: "",
    date: "",
    refreshDateInput: 0,
    currentValue: "",
    displayedValue: "",
    isSafari: false,
    errorDate: false,
  }),
  created() {
    bus.$on("resetDatePicker", async () => {
      //return await this.setData();
      this.currentValue = "";
      this.displayedValue = "";
    });
  },

  mounted() {
    // make sure this.datePicker is in RFC2822 format
    if (this.value) {
      this.displayedValue = this.$moment(this.value).format("DD/MM/YYYY");
      this.currentValue = this.$moment(this.value).format("YYYY-MM-DD");
    }
    if (window.navigator.vendor == "Apple Computer, Inc.") {
      this.isSafari = true;
    }
  },
  methods: {
    // vérifie le format de la date lorsque l'utilisateur quitte l'input (blur)
    checkDate(e) {
      // regex JJ/MM/AAAA
      var regex = /[0-3][0-9]\/[0-1][0-9]\/[1-2][0-9][0-9][0-9]/g;
      // si ça ne correspond pas : erreur
      if (this.displayedValue.match(regex) == null) {
        this.errorDate = true;
      }
      // sinon construction de l'objet Date
      else {
        this.errorDate = false;
        console.log("re-write date");
      }
    },
    checkInput(e) {
      // si c'est pas la touche Retour Tab, <-, ->, CMD+A, CTRL+A,  CMD+X, CTRL+X
      if (
        e.keyCode != 8 &&
        e.keyCode != 9 &&
        e.keyCode != 37 &&
        e.keyCode != 39 &&
        !(e.key == "a" && e.metaKey == true) &&
        !(e.key == "a" && e.ctrlKey == true) &&
        !(e.key == "x" && e.metaKey == true) &&
        !(e.key == "x" && e.ctrlKey == true)
      ) {
        // si y'a 10 caractères
        if (this.displayedValue.length == 10) {
          e.preventDefault();
        }
        // si c'est pas une lettre
        if (isNaN(parseInt(e.key))) {
          e.preventDefault();
          return false;
        }
        // rajout des slashs
        if (
          this.displayedValue.length == 2 ||
          this.displayedValue.length == 5
        ) {
          this.displayedValue += "/";
        }
      }
    },
    changeDate(date) {
      this.datePicker = translateDate(date, false);
      this.date = translateDate(date, false);
      this.$refs.dp_menu.save(false);
      this.dateInput = translateDate(date, false);
    },

    inputDateChange() {
      // console.log('sdsds')
    },

    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      futurClasses += this.custom_class;
      futurClasses += " form__input ";
      if (this.disabled) futurClasses += " disabled";
      if (this.fullWidth) {
        futurClasses += " full-width";
      }

      return futurClasses;
    },
  },

  computed: {
    maxInputDate() {
      // console.log(this.$moment().toISOString())
      if (this.maxToday) return this.$moment().format("YYYY-MM-DD");
      if (this.maxDate) return this.maxDate;
      return this.$moment().add(4, "years").format("YYYY-MM-DD");
    },

    minInputDate() {
      // console.log(this.$moment().toISOString())
      if (this.minToday) return this.$moment().format("YYYY-MM-DD");
      if (this.minDate) return this.minDate;
      return null;
    },

    getName() {
      if (this.name) {
        return this.name;
      }
      return this.label.toLowerCase();
    },

    isRequired() {
      return this.rules.indexOf("required") !== -1;
    },

    inputCols() {
      if (this.twoLines) {
        return "12";
      }
      return this.label ? "9" : "12";
    },

    labelCols() {
      return this.twoLines ? "12" : "3";
    },
  },

  watch: {
    currentValue: {
      // allows us to use v-model on our input.
      handler(val) {
        this.$emit("input", val);
        this.displayedValue = val;
      },
      deep: true,
    },
    displayedValue(newVal, oldVal) {
      console.log("displayedValue :>> ", this.displayedValue);
      this.currentValue = newVal;

      var regex = /[0-3][0-9]\/[0-1][0-9]\/[1-2][0-9][0-9][0-9]/g;
      // si ça ne correspond pas : erreur
      if (this.currentValue.match(regex) !== null) {
        this.currentValue = this.currentValue.split("/").reverse().join("-");
      }
    },
  },

  props: {
    model: {
      type: String,
    },
    value: {
      type: [String, Date, Object],
      default: "",
    },
    placeholder: {
      type: [String, Date],
      default: "",
    },
    customRef: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    twoLines: {
      type: Boolean,
      default: false,
    },
    custom_class: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: [Boolean, String],
      default: "",
    },
    maxToday: {
      type: Boolean,
      default: null,
    },
    minToday: {
      type: Boolean,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    minDate: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    areErrors: {
      type: Boolean,
      default: false,
    },
    errorsMessages: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.ffme__date-picker {
  input {
    border: 1px solid #dfe5f9;
    background: #fff;
    padding: 4px 5px;
    font-weight: 200;
    font-size: 0.8rem;
    width: 130px;
    &:disabled {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
// .ffme__date-picker {
//   font-weight: 200;
//   font-size: 0.8rem;
//   label.v-label {
//     padding-left: 10px !important;
//     top: 0!important;
//   }

//   .field__value-column {
//     > span {
//       width: 100%;
//     }
//   }

//   label {
//     font-weight: 200;
//     margin-bottom: 0;
//     padding-top: 5px;
//     font-size: 0.8rem;
//   }

//   input {
//     border: none;
//     padding: 0;
//     font-weight: 200;
//     font-size: 0.8rem;
//     width: 100%;
//     line-height: 22px;

//     &.is-invalid {
//       border-color: red;
//     }
//     &:focus {
//       outline: none;
//     }

//     &.full-width {
//       max-width: 100%;
//     }
//   }
//   input,
//   .v-input__slot,
//   .v-text-field {
//     cursor: pointer !important;
//   }
//   /*v-input theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed*/
//   .v-input.v-text-field {
//     max-width: 400px;

//     .v-input__control {
//       min-height: 10px;

//       .v-input__slot {
//         min-height: 32px;
//         box-shadow: none;
//         margin: 0;
//         border: 1px solid #dfe5f9;
//         border-radius: 0;
//         padding: 4px 5px;
//         background: #fff !important;
//       }
//     }
//   }
//   .v-text-field__slot {
//     label {
//       line-height: 0.8rem;
//     }
//     input {
//       color: rgba(0, 0, 0, 1);
//       padding-left: 6px;
//       /*padding-top: 1px;*/
//       /*line-height: 21px;*/
//     }
//   }
//   .v-text-field__details {
//     display: none;
//   }
// }
</style>
