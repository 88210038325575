<template>
    
    <div>
        <span class="font-weight-bold">{{context.tarif.NomTarif}}: </span> {{context.tarif.MontantUnitaire}}€ - <a @click="openTarifModal">Modifier</a>
        <tarifModal :context="context" :modalIsOpen="modalIsOpen" v-if="modalIsOpen" @onClose="modalIsOpen = false" @onUpdated="onUpdated" />
    </div>

</template>


<script>

import tarifModal from "@/components/utils/modals/Tarif/Tarif__Modal"
import {success_notification} from "@/plugins/notifications"

export default {
    
    components: {tarifModal},

    data: () => ({
        modalIsOpen: false
    }),
    
    methods: {

        openTarifModal() {
            this.modalIsOpen = true
        },
        onUpdated() {
            this.modalIsOpen = false
            success_notification('Tarif mis à jour')
            this.$emit('onUpdated')
        }

    },
    
    props: {
        context: {
            type: Object
        }
    }

}

</script>