<template>
    <v-col v-if="mounted">

        <div class="px-4 py-3">

            <h5>Tarifs {{ structure.NomStructure }} {{ SaisonName }} <a @click="AddTarif">Ajouter un tarif</a></h5>
            <div v-if="tarifsClub.length">
                <div v-for='(tarif, index) in tarifsClub' :key='index'>
                    <LineTarif :context="{...context, tarif: tarif}" @onUpdated="setData"/>
                </div>   
            </div>
            <div v-else>
                <span class="font-weight-bold">Aucun tarif renseigné</span>
            </div>
           
        </div>

        <tarifModal :context="context" :modalIsOpen="modalIsOpen" v-if="modalIsOpen" @onClose="modalIsOpen = false" @onUpdated="onUpdated" />

        
    </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LineTarif from "./LineTarif"
import tarifModal from "@/components/utils/modals/Tarif/Tarif__Modal"
import {success_notification} from "@/plugins/notifications"

export default {

    components: {LineTarif, tarifModal}, 
    
    data: () => ({
        mounted: false,
        tarifsClub: [],
        structure: {},
        loading: false,
        modalIsOpen: false
    }),

    async created() {
        await this.setData();
        this.mounted = true;
    },

    computed: {
        ...mapGetters("saisons", ['currentSaison', "selectedSaison"]),

        SaisonName() {
            return this.selectedSaison.NomSaison;
        },

    },

    methods : {

        ...mapActions("basics", ['getCurrentClubTarif']),

        async setData() {

            this.tarifsClub = await this.getCurrentClubTarif({ID_Structure: this.context.ID_Structure, ID_Saison: this.selectedSaison.id});
            console.log(' this.tarifsClub:',  this.tarifsClub)
            if(this.context.structure) {
                this.structure = this.context.structure.STR_Structure
            }else {
                this.structure = await this.getStructureByID(this.context.ID_Structure)
            }
            return true      
        },
        AddTarif() {
            this.modalIsOpen = true
        },
        async onUpdated() {
            this.modalIsOpen = false
            await this.setData()
            success_notification('Tarif ajouté')

        }

    },

    props: {
        context: {
            type: Object,   
        }
    }



}

</script>