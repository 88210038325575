<template>
  <div class="w-100" v-if="mounted" style="margin: -30px 0 0">
    <div style="margin: 0 -15px">
      <v-row style="background: rgba(0,0,0,.1);">
        <CurrentTarifFFME :context="{ ID_Saison: selectedSaison.id }" />
      </v-row>

      <v-row v-if="Ligue_Id" style="background: rgba(0,0,0,.03);">
        <CurrentTarifLigue :context="{ ID_Structure: Ligue_Id }" />
      </v-row>

      <v-row v-if="ComiteTerritorial_Id" style="background: rgba(0,0,0,.05);">
        <CurrentTarifCT :context="{ ID_Structure: ComiteTerritorial_Id }" />
      </v-row>

      <v-row v-if="isClub" style="background: rgba(0,0,0,.02);">
        <CurrentTarifClub
          :context="{
            ID_Structure: currentStructureId,
            structure: currentStructure,
          }"
        />
      </v-row>
    </div>
  </div>
  <div v-else>
    Chargement...
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CurrentTarifFFME from "@/components/Tarifs/TarifsFFME_CurrentList";
import CurrentTarifCT from "@/components/Tarifs/TarifsCT_CurrentList";
import CurrentTarifLigue from "@/components/Tarifs/TarifsLigue_CurrentList";
import CurrentTarifClub from "@/components/Tarifs/TarifsClub_CurrentList";

export default {
  name: "AccueilEtContact",

  components: {
    CurrentTarifFFME,
    CurrentTarifCT,
    CurrentTarifClub,
    CurrentTarifLigue,
  },

  data: () => ({
    loading: false,
    mounted: false,
  }),

  async created() {
    // await this.setData()
    this.$emit("updateTitle", "Cotisation licence");
    console.log("qsdfqsdqsdq", this.selectedSaison);
    console.log("currentStructure", this.currentStructureId);
    this.mounted = true;
  },

  methods: {
    ...mapActions("basics", ["getCurrentClubTarif"]),

    async setData() {},
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId", "currentStructure"]),
    ...mapGetters("saisons", ["currentSaisonId", "selectedSaison"]),

    ComiteTerritorial_Id() {
      if (this.isCt) return this.currentStructureId;
      return this.currentStructure.STR_Structure.ID_ComiteTerritorial;
    },

    Ligue_Id() {
      if (this.isLigue) return this.currentStructureId;
      return this.currentStructure.STR_Structure.ID_Ligue;
    },

    isFFME() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "service_federal_ffme";
    },

    isCt() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "comite_territorial";
    },

    isLigue() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType;
      return type.SlugTypeStructure == "ligue";
    },

    isClub() {
      return this.currentStructure.STR_Structure.LIST_StructureType.EST_Club;
    },
  },
};
</script>
