<template>
  <v-row>
    <v-row style="width: 100%">
      <v-col cols="12" md="8"
        ><h5>{{ title }}</h5></v-col
      >
    </v-row>

    <v-row v-if="mounted">
      <DocumentType
        v-for="(item, index) in allDocuments"
        :key="index"
        :document="item"
        :context="context"
        :isAffiliation="isAffiliation"
        :documentUploaded="documentUploaded"
        :ID_DocCompteRenduAg="affiliation.ID_DocCompteRenduAg"
      />
    </v-row>
    <v-row v-if="mounted" style="margin: 10vh 10px 15px">
      <v-divider class="divider" color="#1A237E"></v-divider>
      <div class="engagement_etatique">
        <!-- <span v-if="isClub"
          >Contrat club :
          {{ this.affiliation.isContrat_Club_EA ? "Oui" : "Non" }}</span
        > -->
        <span v-if="isEA"
          >Convention d’établissement :
          {{ this.affiliation.isContrat_Club_EA ? "Oui" : "Non" }}</span
        >
        <span
          >Contrat d’engagement républicain :
          {{ this.affiliation.isRepublicain_engagement ? "Oui" : "Non" }}</span
        >
      </div>
    </v-row>
    <v-row v-if="isAffiliation" style="width: 100%">
      <v-col cols="12">
        <v-btn
          type="button"
          class="btn btn-primary"
          :disabled="!stepIsValid"
          @click="() => { this.$router.push({ name: 'affiliations__gestion_des_salaries' }) }"
          >Étape suivante</v-btn
        >
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import DocumentType from "@/components/GestionDesDocuments/GestionDesDocuments__Type.vue";
import { mapGetters, mapActions } from "vuex";
import { bus } from "@/main";
import {
  getStructureDocumentsByStructureId,
} from "@/plugins/structureService";
import { BALANCE_SHEET, DELEGATE_MANDATE, GENERAL_MEETING_REPORT, INSURANCE_CERTIFICATE, INTERNAL_RULES, JO_PUBLICATION, PROFESSIONAL_CARD, RECEPISSE_PREFECTORAL_DECLARATION, RESULT_REPORT, RIB, STATUS, SUPERVISOR_DIPLOMA, TREASURY_REPORT } from "../../constants/structureDocumentType";

export default {
  components: { DocumentType },

  data: () => ({
    affiliation: {},
    allDocumentType: [],
    structureType: {},
    mounted: false,
    structure: {},
    structureDocuments: [],

    stepIsValid: false,
  }),

  async created() {
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "currentStructureType"]),

    title() {
      if (this.structureType.EST_Club && this.isAffiliation) {
        return "Etape 3 - Saisie des documents";
      }
      return "Documents officiels";
    },

    allDocuments() {
      let docs = [this.DOC_Status];

      if (this.structureType.EST_Club && !this.isAffiliation) {
        docs.push(this.DOC_CompteRenduAg);
        docs.push(this.DOC_RapportTresorerie);
        docs.push(this.DOC_Mandat);
        if (this.structureType.SlugTypeStructure == "club_association") {
          docs.push(this.DOC_ParutionJO);
          docs.push(this.DOC_RecepissePrefectoralDeclaration);
          //docs.push(this.DOC_ContratClub);
          docs.push(this.DOC_ReglementInterieur);
        } else if (
          this.structureType.SlugTypeStructure == "club_section_association"
        ) {
          docs.push(this.DOC_ParutionJO);
          docs.push(this.DOC_RecepissePrefectoralDeclaration);
          docs.push(this.DOC_ReglementInterieur);
        } else if (
          this.structureType.SlugTypeStructure == "club_etablissement_affilie"
        ) {
          docs.push(this.DOC_AttestationAssurance);
          docs.push(this.DOC_CarteProfessionnelle);
          docs.push(this.DOC_DiplomeEncadrant);
        }
      } else if (this.structureType.EST_Club && this.isAffiliation) {
        if (
          this.structureType.SlugTypeStructure == "club_association"
          || this.structureType.SlugTypeStructure == "club_section_association"
        ) {
          docs.unshift(this.DOC_CompteRenduAg);
          docs.push(this.DOC_ReglementInterieur);
          docs.push(this.DOC_RapportTresorerie);
        }

        if (this.structureType.SlugTypeStructure === 'club_etablissement_affilie') {
          docs.unshift(this.DOC_DiplomeEncadrant);
          docs.unshift(this.DOC_CarteProfessionnelle);
          docs.unshift(this.DOC_AttestationAssurance);
        }
      } else if (
        this.structureType.SlugTypeStructure == "service_federal_ffme"
      ) {
        docs.push(this.DOC_ReglementInterieur);
        docs.push(this.DOC_ReglementFinancier);
        docs.push(this.DOC_ReglementAntiDopage);
        docs.push(this.DOC_ReglementMedical);
        docs.push(this.DOC_ReglementDisciplinaire);
        docs.push(this.DOC_Rgpd);
        docs.push(this.DOC_Rib);
      } else if (this.structureType.SlugTypeStructure == "ligue") {
        docs.push(this.DOC_BilanFinancier);
        docs.push(this.DOC_CompteResultat);
        docs.push(this.DOC_CompteRenduAg);
      } else if (this.structureType.SlugTypeStructure == "comite_territorial") {
        docs.push(this.DOC_BilanFinancier);
        docs.push(this.DOC_CompteResultat);
        docs.push(this.DOC_CompteRenduAg);
      }

      // }else if (this.structureType.SlugTypeStructure == "ligue"){

      // }
      return docs;
    },

    DOC_Rib() {
      const strDocRib = this.structureDocuments.find(doc => doc.type === RIB);
      const rib = this.affiliation.DOC_Rib;

      if (strDocRib && rib) {
          return new Date(strDocRib.createdAt) > new Date(rib.Z_DateCreation) 
              ? { data : strDocRib, isNewDoc: true }  
              : { data: rib, isNewDoc: false };
      } else if (strDocRib) {
          return { data : strDocRib, isNewDoc: true };
      } else if (rib) {
          return { data: rib, isNewDoc: false };
      }

      return this.getDefaultDoc("rib");
    },
    DOC_CompteResultat() {
      const strDocResultReport = this.structureDocuments.find(doc => doc.type === RESULT_REPORT);
      const compteResultat = this.affiliation.DOC_CompteResultat;

      if (strDocResultReport && compteResultat) {
          return new Date(strDocResultReport.createdAt) > new Date(compteResultat.Z_DateCreation) 
              ? { data : strDocResultReport, isNewDoc: true }  
              : { data: compteResultat, isNewDoc: false };
      } else if (strDocResultReport) {
          return { data : strDocResultReport, isNewDoc: true };
      } else if (compteResultat) {
          return { data: compteResultat, isNewDoc: false };
      }

      return this.getDefaultDoc("compte_resultat");
    },
    DOC_BilanFinancier() {
      const strDocBalanceSheet = this.structureDocuments.find(doc => doc.type === BALANCE_SHEET);
      const bilanFinancier = this.affiliation.DOC_BilanFinancier;

      if (strDocBalanceSheet && bilanFinancier) {
          return new Date(strDocBalanceSheet.createdAt) > new Date(bilanFinancier.Z_DateCreation) 
              ? { data : strDocBalanceSheet, isNewDoc: true }  
              : { data: bilanFinancier, isNewDoc: false };
      } else if (strDocBalanceSheet) {
          return { data : strDocBalanceSheet, isNewDoc: true };
      } else if (bilanFinancier) {
          return { data: bilanFinancier, isNewDoc: false };
      }

      return this.getDefaultDoc("bilan_financier");
    },
    DOC_Status() {
      const strDocStatus = this.structureDocuments.find(doc => doc.type === STATUS);
      const status = this.affiliation.DOC_Status;

      if (strDocStatus && status) {
          return new Date(strDocStatus.createdAt) > new Date(status.Z_DateCreation) 
              ? { data : strDocStatus, isNewDoc: true }  
              : { data: status, isNewDoc: false };
      } else if (strDocStatus) {
          return { data : strDocStatus, isNewDoc: true };
      } else if (status) {
          return { data: status, isNewDoc: false };
      }

      return this.getDefaultDoc("status");
    },

    DOC_ContratClub() {
      const strDocClubContract = this.structureDocuments.find(doc => doc.type === 'club_contract');
      const contratClub = this.affiliation.DOC_ContratClub;

      if (strDocClubContract && contratClub) {
          return new Date(strDocClubContract.createdAt) > new Date(contratClub.Z_DateCreation) 
              ? { data : strDocClubContract, isNewDoc: true }  
              : { data: contratClub, isNewDoc: false };
      } else if (strDocClubContract) {
          return { data : strDocClubContract, isNewDoc: true };
      } else if (contratClub) {
          return { data: contratClub, isNewDoc: false };
      }

      return this.getDefaultDoc("contrat_club");
    },

    DOC_CompteRenduAg() {
      const strDocGeneralMeeting = this.structureDocuments.find(doc => doc.type === GENERAL_MEETING_REPORT);
      const compteRenduAg = this.affiliation.DOC_CompteRenduAg;

      if (strDocGeneralMeeting && compteRenduAg) {
          return new Date(strDocGeneralMeeting.createdAt) > new Date(compteRenduAg.Z_DateCreation) 
              ? { data : strDocGeneralMeeting, isNewDoc: true }  
              : { data: compteRenduAg, isNewDoc: false };
      } else if (strDocGeneralMeeting) {
          return { data : strDocGeneralMeeting, isNewDoc: true };
      } else if (compteRenduAg) {
          return { data: compteRenduAg, isNewDoc: false };
      }

      return this.getDefaultDoc("compte_rendu_ag");
    },
    DOC_Mandat() {
      const strDocDelegateMandate = this.structureDocuments.find(doc => doc.type === DELEGATE_MANDATE);
      const mandatDelegue = this.affiliation.DOC_Mandat;

      if (strDocDelegateMandate && mandatDelegue) {
          return new Date(strDocDelegateMandate.createdAt) > new Date(mandatDelegue.Z_DateCreation) 
              ? { data : strDocDelegateMandate, isNewDoc: true }  
              : { data: mandatDelegue, isNewDoc: false };
      } else if (strDocDelegateMandate) {
          return { data : strDocDelegateMandate, isNewDoc: true };
      } else if (mandatDelegue) {
          return { data: mandatDelegue, isNewDoc: false };
      }

      return this.getDefaultDoc("mandat_représentant_légal");
    },

    DOC_RapportTresorerie() {
      const strDocTreasuryReport = this.structureDocuments.find(doc => doc.type === TREASURY_REPORT);
      const rapportTresorerie = this.affiliation.DOC_RapportTresorerie;

      if (strDocTreasuryReport && rapportTresorerie) {
          return new Date(strDocTreasuryReport.createdAt) > new Date(rapportTresorerie.Z_DateCreation) 
              ? { data : strDocTreasuryReport, isNewDoc: true }  
              : { data: rapportTresorerie, isNewDoc: false };
      } else if (strDocTreasuryReport) {
          return { data : strDocTreasuryReport, isNewDoc: true };
      } else if (rapportTresorerie) {
          return { data: rapportTresorerie, isNewDoc: false };
      }

      return this.getDefaultDoc("rapport_tresorerie");
    },

    DOC_ParutionJO() {
      const strDocJoPublication = this.structureDocuments.find(doc => doc.type === JO_PUBLICATION);
      const parutionJo = this.affiliation.DOC_ParutionJO;

      if (strDocJoPublication && parutionJo) {
          return new Date(strDocJoPublication.createdAt) > new Date(parutionJo.Z_DateCreation) 
              ? { data : strDocJoPublication, isNewDoc: true }  
              : { data: parutionJo, isNewDoc: false };
      } else if (strDocJoPublication) {
          return { data : strDocJoPublication, isNewDoc: true };
      } else if (parutionJo) {
          return { data: parutionJo, isNewDoc: false };
      }

      return this.getDefaultDoc("parution_jo");
    },

    DOC_AttestationAssurance() {
      const strDocInsuranceCertificate = this.structureDocuments.find(doc => doc.type === INSURANCE_CERTIFICATE);
      const attestationAssurance = this.affiliation.DOC_AttestationAssurance;

      if (strDocInsuranceCertificate && attestationAssurance) {
          return new Date(strDocInsuranceCertificate.createdAt) > new Date(attestationAssurance.Z_DateCreation) 
              ? { data : strDocInsuranceCertificate, isNewDoc: true }  
              : { data: attestationAssurance, isNewDoc: false };
      } else if (strDocInsuranceCertificate) {
          return { data : strDocInsuranceCertificate, isNewDoc: true };
      } else if (attestationAssurance) {
          return { data: attestationAssurance, isNewDoc: false };
      }

      return this.getDefaultDoc("attestation_assurance");
    },

    DOC_CarteProfessionnelle() {
      const strDocProfessionalCard = this.structureDocuments.find(doc => doc.type === PROFESSIONAL_CARD);
      const carteProfessionnelle = this.affiliation.DOC_CarteProfessionnelle;

      if (strDocProfessionalCard && carteProfessionnelle) {
          return new Date(strDocProfessionalCard.createdAt) > new Date(carteProfessionnelle.Z_DateCreation) 
              ? { data : strDocProfessionalCard, isNewDoc: true }  
              : { data: carteProfessionnelle, isNewDoc: false };
      } else if (strDocProfessionalCard) {
          return { data : strDocProfessionalCard, isNewDoc: true };
      } else if (carteProfessionnelle) {
          return { data: carteProfessionnelle, isNewDoc: false };
      }

      return this.getDefaultDoc("copie_cartes_professionnelles");
    },

    DOC_DiplomeEncadrant() {
      const strDocSupervisorDiploma = this.structureDocuments.find(doc => doc.type === SUPERVISOR_DIPLOMA);
      const diplomeEncadrant = this.affiliation.DOC_DiplomeEncadrant;

      if (strDocSupervisorDiploma && diplomeEncadrant) {
          return new Date(strDocSupervisorDiploma.createdAt) > new Date(diplomeEncadrant.Z_DateCreation) 
              ? { data : strDocSupervisorDiploma, isNewDoc: true }  
              : { data: diplomeEncadrant, isNewDoc: false };
      } else if (strDocSupervisorDiploma) {
          return { data : strDocSupervisorDiploma, isNewDoc: true };
      } else if (diplomeEncadrant) {
          return { data: diplomeEncadrant, isNewDoc: false };
      }

      return this.getDefaultDoc("diplome_encadrant");
    },

    DOC_RecepissePrefectoralDeclaration() {
      const strDocRecepissePrefectoralDelcaration = this.structureDocuments.find(doc => doc.type === RECEPISSE_PREFECTORAL_DECLARATION);
      const recepissePrefectoralDeclaration = this.affiliation.DOC_RecepissePrefectoralDeclaration;

      if (strDocRecepissePrefectoralDelcaration && recepissePrefectoralDeclaration) {
          return new Date(strDocRecepissePrefectoralDelcaration.createdAt) > new Date(recepissePrefectoralDeclaration.Z_DateCreation) 
              ? { data : strDocRecepissePrefectoralDelcaration, isNewDoc: true }  
              : { data: recepissePrefectoralDeclaration, isNewDoc: false };
      } else if (strDocRecepissePrefectoralDelcaration) {
          return { data : strDocRecepissePrefectoralDelcaration, isNewDoc: true };
      } else if (recepissePrefectoralDeclaration) {
          return { data: recepissePrefectoralDeclaration, isNewDoc: false };
      }

      return this.getDefaultDoc("recepisse_prefectoral_declaration");
    },

    DOC_ReglementInterieur() {
      const strDocInternalRules = this.structureDocuments.find(doc => doc.type === INTERNAL_RULES);
      const reglementInterieur = this.affiliation.DOC_ReglementInterieur;

      if (strDocInternalRules && reglementInterieur) {
          return new Date(strDocInternalRules.createdAt) > new Date(reglementInterieur.Z_DateCreation) 
              ? { data : strDocInternalRules, isNewDoc: true }  
              : { data: reglementInterieur, isNewDoc: false };
      } else if (strDocInternalRules) {
          return { data : strDocInternalRules, isNewDoc: true };
      } else if (reglementInterieur) {
          return { data: reglementInterieur, isNewDoc: false };
      }

      return this.getDefaultDoc("reglement_interieur");
    },

    DOC_ReglementFinancier() {
      const strDocFinancialRules = this.structureDocuments.find(doc => doc.type === 'financial_rules');
      const reglementFinancier = this.affiliation.DOC_ReglementFinancier;

      if (strDocFinancialRules && reglementFinancier) {
          return new Date(strDocFinancialRules.createdAt) > new Date(reglementFinancier.Z_DateCreation) 
              ? { data : strDocFinancialRules, isNewDoc: true }  
              : { data: reglementFinancier, isNewDoc: false };
      } else if (strDocFinancialRules) {
          return { data : strDocFinancialRules, isNewDoc: true };
      } else if (reglementFinancier) {
          return { data: reglementFinancier, isNewDoc: false };
      }

      return this.getDefaultDoc("reglement_financier");
    },

    DOC_ReglementMedical() {
      const strDocMedicalRules = this.structureDocuments.find(doc => doc.type === 'medical_rules');
      const reglementMedical = this.affiliation.DOC_ReglementMedical;

      if (strDocMedicalRules && reglementMedical) {
          return new Date(strDocMedicalRules.createdAt) > new Date(reglementMedical.Z_DateCreation) 
              ? { data : strDocMedicalRules, isNewDoc: true }  
              : { data: reglementMedical, isNewDoc: false };
      } else if (strDocMedicalRules) {
          return { data : strDocMedicalRules, isNewDoc: true };
      } else if (reglementMedical) {
          return { data: reglementMedical, isNewDoc: false };
      }

      return this.getDefaultDoc("reglement_medical");
    },

    DOC_ReglementAntiDopage() {
      const strDocAntiDopingRules = this.structureDocuments.find(doc => doc.type === 'anti_doping_rules');
      const reglementAntiDopage = this.affiliation.DOC_ReglementAntiDopage;

      if (strDocAntiDopingRules && reglementAntiDopage) {
          return new Date(strDocAntiDopingRules.createdAt) > new Date(reglementAntiDopage.Z_DateCreation) 
              ? { data : strDocAntiDopingRules, isNewDoc: true }  
              : { data: reglementAntiDopage, isNewDoc: false };
      } else if (strDocAntiDopingRules) {
          return { data : strDocAntiDopingRules, isNewDoc: true };
      } else if (reglementAntiDopage) {
          return { data: reglementAntiDopage, isNewDoc: false };
      }

      return this.getDefaultDoc("reglement_anti_dopage");
    },

    DOC_ReglementDisciplinaire() {
      const strDocDisciplinaryRules = this.structureDocuments.find(doc => doc.type === 'disciplinary_rules');
      const reglementDisciplinaire = this.affiliation.DOC_ReglementDisciplinaire;

      if (strDocDisciplinaryRules && reglementDisciplinaire) {
          return new Date(strDocDisciplinaryRules.createdAt) > new Date(reglementDisciplinaire.Z_DateCreation) 
              ? { data : strDocDisciplinaryRules, isNewDoc: true }  
              : { data: reglementDisciplinaire, isNewDoc: false };
      } else if (strDocDisciplinaryRules) {
          return { data : strDocDisciplinaryRules, isNewDoc: true };
      } else if (reglementDisciplinaire) {
          return { data: reglementDisciplinaire, isNewDoc: false };
      }

      return this.getDefaultDoc("reglement_disciplinaire");
    },

    DOC_Rgpd() {
      const strDocRgpd = this.structureDocuments.find(doc => doc.type === 'rgpd');
      const rgpd = this.affiliation.DOC_Rgpd;

      if (strDocRgpd && rgpd) {
          return new Date(strDocRgpd.createdAt) > new Date(rgpd.Z_DateCreation) 
              ? { data : strDocRgpd, isNewDoc: true }  
              : { data: rgpd, isNewDoc: false };
      } else if (strDocRgpd) {
          return { data : strDocRgpd, isNewDoc: true };
      } else if (rgpd) {
          return { data: rgpd, isNewDoc: false };
      }

      return this.getDefaultDoc("rgpd");
    },

    isClub() {
      let typestructure = this.structure
        ? this.structure.LIST_StructureType.SlugTypeStructure
        : this.currentStructureType.SlugTypeStructure;
      return typestructure == "club_association";
    },

    isEA() {
      let typestructure = this.structure
        ? this.structure.LIST_StructureType.SlugTypeStructure
        : this.currentStructureType.SlugTypeStructure;
      return typestructure == "club_etablissement_affilie";
    },
  },

  methods: {
    ...mapActions("structure", [
      "getStructureAffiliation",
      "updateAffiliationDocument",
      "getStructureType",
      "getStructureByID",
    ]),
    ...mapActions("basics", ["getListTypesDocument"]),

    async setData() {
      let strType = await this.getStructureType({
        ID_Structure: this.context.ID_Structure,
      });
      this.structureType = strType.LIST_StructureType;

      // Filter the doc type by structure type !!!
      this.setDocumentType(await this.getListTypesDocument());

      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.context.ID_Structure,
        ID_Saison: this.context.ID_Saison,
      });
      
      const structureID = this.$route.params.id ? parseInt(this.$route.params.id, 10) : this.context.ID_Structure;
      
      const structureDocuments = await getStructureDocumentsByStructureId(structureID);
      this.structureDocuments = structureDocuments;

      this.structure = await this.getStructureByID(structureID);
      console.log("🚀 ~ setData ~ this.getStructure", this.structure);

      this.validateStep();

      return;
    },

    async documentUploaded() {
      await this.setData();
    },

    setDocumentType(documentType) {
      if (this.structureType.EST_Club) {
        this.allDocumentType = documentType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_club"
        );
      } else if (this.structureType.SlugTypeStructure == "comite_territorial") {
        this.allDocumentType = documentType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_ct"
        );
      } else if (this.structureType.SlugTypeStructure == "ligue") {
        this.allDocumentType = documentType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_ligue"
        );
      } else if (
        this.structureType.SlugTypeStructure == "service_federal_ffme"
      ) {
        this.allDocumentType = documentType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_ffme"
        );
      }
    },

    // Get the document type and generate a default object
    getDefaultDoc(slugType) {
      let doc = {
        CheminDocument: "",
        DateFinValiditeDocument: "",
        EST_Actif: true,
        EST_DocumentValide: false,
        ID_Structure: this.context.ID_Structure,
        ID_Type_Document: "",
        NomDocument: "",
      };
      let type = this.allDocumentType.find(
        (doc) => doc.SlugTypeDocument == slugType
      );
      doc.ID_Type_Document = type?.id;

      return { data: doc, isNewDoc: false };
    },

    async validateStep() {
      const isGeneralMeetingDocValid = (this.DOC_CompteRenduAg.isNewDoc && this.DOC_CompteRenduAg.data.file.id) || (!this.DOC_CompteRenduAg.isNewDoc && this.DOC_CompteRenduAg.data.CheminDocument);

      if (this.structureType.EST_Club) {
        if (
          this.structureType.SlugTypeStructure == "club_association"
          || this.structureType.SlugTypeStructure == "club_section_association"
        ) {
          if (isGeneralMeetingDocValid) {
            this.stepIsValid = true;
            bus.$emit('onRefreshNavigation', true);

            return true;
          }
        }

        if (this.structureType.SlugTypeStructure === 'club_etablissement_affilie') {
          const isSupervisorDiplomaDocValid = (this.DOC_DiplomeEncadrant.isNewDoc && this.DOC_DiplomeEncadrant.data.file.id) || (!this.DOC_DiplomeEncadrant.isNewDoc && this.DOC_DiplomeEncadrant.data.CheminDocument);
          const isProfessionalCardDocValid = (this.DOC_CarteProfessionnelle.isNewDoc && this.DOC_CarteProfessionnelle.data.file.id) || (!this.DOC_CarteProfessionnelle.isNewDoc && this.DOC_CarteProfessionnelle.data.CheminDocument);
          const isInsuranceCertificateDocValid = (this.DOC_AttestationAssurance.isNewDoc && this.DOC_AttestationAssurance.data.file.id) || (!this.DOC_AttestationAssurance.isNewDoc && this.DOC_AttestationAssurance.data.CheminDocument);

          if (
            isSupervisorDiplomaDocValid
            && isProfessionalCardDocValid
            && isInsuranceCertificateDocValid
          ) {
            this.stepIsValid = true;
            bus.$emit('onRefreshNavigation', true);

            return true;
          }
        }
      }

      const isStatusDocValid = (this.DOC_Status.isNewDoc && this.DOC_Status.data.file.id) || (!this.DOC_Status.isNewDoc && this.DOC_Status.data.CheminDocument);

      if (
        isGeneralMeetingDocValid
        && isStatusDocValid
      ) {
        this.stepIsValid = true;
        bus.$emit('onRefreshNavigation', true);

        return true;
      }

      this.stepIsValid = false;

      return false;
    },
  },

  props: {
    context: {
      type: Object,
    },
    isAffiliation: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.divider {
  border-width: 2px !important;
}
.engagement_etatique {
  margin-top: 2vh;
  width: 100%;
  span {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
}
</style>
