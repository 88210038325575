<template>
  <div>
    <v-card max-width="344" outlined>
      <v-list-item v-if="adresse" three-line>
        <v-list-item-content>
          <div class="mb-4 font-weight-medium text-uppercase">
            {{ adresse.NomAdresse }}
          </div>
          <div>{{ adresse.Adresse1 }}</div>
          <div>{{ adresse.Adresse2 }}</div>
          <div>{{ adresse.CodePostal }}</div>
          <div>{{ adresse.Ville }}</div>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-else>
        <v-list-item-content class="">
          <div>Aucune adresse de correspondance assignée</div>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <div class="mt-4">
      <v-btn
        :disabled="!context.hasCodeFederal || disabled"
        @click="adresseModalIsOpen = true"
        class="btn btn-primary"
        >Sélectionner une adresse</v-btn
      >
    </div>

    <ModalAdresse
      v-if="adresseModalIsOpen"
      :item="adresse"
      :isOpen="adresseModalIsOpen"
      @onClose="adresseModalIsOpen = false"
      @onSelected="adresseSelected"
      @onAdded="refresh++"
      :key="refresh"
      :context="context"
      ref="adresseModal"
      :tabInit="1"
    ></ModalAdresse>
  </div>
</template>

<script>
import ModalAdresse from "@/components/utils/modals/adresses.vue";
import { mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
export default {
  components: { ModalAdresse },

  data: () => ({
    adresseModalIsOpen: false,
    refresh: 0,
  }),

  methods: {
    ...mapActions("structure", ["updateStructureAdresseAssignement"]),
    async adresseSelected(adresse) {
      let payload = {
        type: this.context.type,
        adresse: adresse,
        ID_Structure: this.context.ID_Structure,
      };
      try {
        await this.updateStructureAdresseAssignement(payload);
        success_notification("Adresse assignée");
        this.$emit("onCardUpdate", adresse);
        this.refresh++;
        this.adresseModalIsOpen = false;
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
      }
    },
  },

  props: {
    adresse: {
      type: [Object, Number],
      default: null,
    },
    context: {
      type: Object,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
