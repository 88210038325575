<template>
  <l-map
    :zoom="zoom"
    :center="center"
    style="width: 100%"
    @click="setNewMarker"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <!-- Use default icon -->
    <l-marker :lat-lng="markerPosition">
      <l-popup>
        {{ `[ ${markerPosition[0]}, ${markerPosition[1]} ]` }}
      </l-popup>
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "CustomMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      show: false,
      zoom: 15,
      // center: [48.852969, 2.349903],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      markerPosition: [],
    };
  },

  methods: {
    setNewMarker(item) {
      const { lat, lng } = item.latlng;
      this.markerPosition = [lat, lng];
      this.$emit("set-location", [lat, lng]);
    },
    setNewPosition() {
      this.markerPosition = [...this.center];
    },
  },
  props: {
    center: {
      type: Array,
      default: () => [48.852969, 2.349903],
    },
  },
  watch: {
    center: {
      handler: "setNewPosition",
      immediate: true,
    },
  },
};
</script>
