<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="close()"
    max-width="600px"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display:flex;flex-direction:column;">
        <span style="font-size:1.2rem;">
          Tarif
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat class="px-10 py-6">
          <ValidateForm
            @validated="submit"
            :loading="loading"
            style="width: 100%;"
            :showFieldObl="false"
          >
            <v-col cols="12">
              <InputSelect
                v-if="readOnly"
                v-model="currentTarif.familleTarif"
                :items="allFamilleTarif"
                label="Type tarif"
                item_text="Name"
                item_value="id"
                rules="required"
                @onChange="familleChange"
                readonly
              />

              <InputSelect
                v-else
                v-model="currentTarif.familleTarif"
                :items="allFamilleTarif"
                label="Type tarif"
                item_text="Name"
                item_value="id"
                rules="required"
                @onChange="familleChange"
              />

              <!-- <div style="font-size: .8rem; text-align: right; height:30px;">
                <a href="#" @click.prevent="openNewTarifFamilleModal"
                  >Ajouter</a
                >
                ||
                <a href="#n" @click.prevent="modifyTarifModalIsOpen = true"
                  >Modifier un type tarif</a
                >
              </div> -->
              <InputText
                type="text"
                v-model="currentTarif.NomTarif"
                rules="required"
                label="Nom tarif"
              />

              <InputText
                type="number"
                :step="0.01"
                rules="required"
                v-model="currentTarif.MontantUnitaire"
                label="Montant tarif"
              />
              <!-- <v-checkbox
                v-model="EST_OptionAdhesion"
                label="Option d'adhésion"
                class="option__adhesion"
              ></v-checkbox> -->
            </v-col>

            <v-card-actions class="">
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :loading="loading"
                rounded
                color="blue-dark text-white font-weight-bold"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </ValidateForm>
        </v-card>
      </v-tabs-items>
    </div>
    <NewFamilleTarifModal
      v-if="newTarifModalIsOpen"
      :openModal="newTarifModalIsOpen"
      :context="context"
      @onUpdated="onUpdatedFamilleTarif"
      @onClose="newTarifModalIsOpen = false"
    />
    <ModifyTarifModal
      v-if="modifyTarifModalIsOpen"
      :openModal="modifyTarifModalIsOpen"
      :context="context"
      :allTarifFamille="allFamilleTarif"
      @on-modify="onModifyFamilleTarif"
      @onClose="modifyTarifModalIsOpen = false"
    />
  </v-dialog>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";
import InputSelect from "@/components/utils/select.vue";

import { mapActions, mapGetters } from "vuex";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import NewFamilleTarifModal from "@/components/utils/modals/Tarif/Tarif__FamilleTarifModal";
import ModifyTarifModal from "@/components/utils/modals/Tarif/Tarif__FamilleTarifModal_modify";

export default {
  components: {
    ValidateForm,
    InputText,
    InputSelect,
    NewFamilleTarifModal,
    ModifyTarifModal,
  },

  data: () => ({
    loading: false,
    mounted: false,
    currentTarif: {},
    currentFamille: {},
    allFamilleTarif: [
      { id: 0, Name: "Tarif d'adhésion club" },
      { id: 1, Name: "Option d'adhésion" },
    ],
    tarifCategorie: [],
    familleTarif: null,
    newTarifModalIsOpen: false,
    tarifsCateg: null,
    EST_OptionAdhesion: false,
    modifyTarifModalIsOpen: false,
  }),

  async created() {
    // this.allFamilleTarif = await this.getAllFamilleTarif({
    //   ID_Structure: this.context.ID_Structure,
    // });

    // this.allFamilleTarif = this.allFamilleTarif.filter(item =>
    // (item.NomFamilleTarif === "Tarif d'adhésion club" || item.NomFamilleTarif === "Option d'adhésion"))

    this.tarifCategorie = await this.getTarifsCategorieClub();
    // console.log(this.tarifCategorie.find(tarif => tarif.SlugCategorieTarif == "tarif_club"))
    this.setData();
    this.mounted = true;
    await this.getTarifcategorie();
  },

  computed: {
    ...mapGetters("saisons", ["selectedSaison"]),
  },

  methods: {
    ...mapActions("structure", [
      "updateAffiliationTarifSructure",
      "getAllFamilleTarif",
    ]),

    ...mapActions("basics", [
      "getTarifsCategorieClub",
      "getListTarifCategorie",
    ]),

    openNewTarifFamilleModal() {
      this.newTarifModalIsOpen = true;
    },
    async getTarifcategorie() {
      this.tarifsCateg = await this.getListTarifCategorie();
    },

    async onUpdatedFamilleTarif() {
      success_notification("Type de tarif enregistré");
      this.allFamilleTarif = await this.getAllFamilleTarif({
        ID_Structure: this.context.ID_Structure,
      });
      this.newTarifModalIsOpen = false;
      return;
    },
    async onModifyFamilleTarif() {
      success_notification("Type de tarif modifié");
      this.allFamilleTarif = await this.getAllFamilleTarif({
        ID_Structure: this.context.ID_Structure,
      });
      this.modifyTarifModalIsOpen = false;
      return;
    },

    setData() {
      if (this.context.tarif) {
        this.currentTarif = { ...this.context.tarif };
        this.EST_OptionAdhesion = this.currentTarif.EST_Objet;
        if (this.currentTarif.ID_FamilleTarif) {
          this.currentTarif.familleTarif = this.allFamilleTarif.find(
            (fm) => fm.id == this.currentTarif.ID_FamilleTarif
          );
        } else if (this.currentTarif.MKP_FamilleTarif) {
          this.currentTarif.familleTarif = this.currentTarif.MKP_FamilleTarif;
        }
      }
      this.currentTarif.familleTarif = this.currentTarif.EST_Objet ? 1 : 0;
    },

    familleChange(item) {
      if (typeof item == "string") {
        if (item == "") return;
        this.currentFamille = { NomFamilleTarif: item };
      }
      if (typeof item == "object") {
        this.currentFamille = item;
        this.currentTarif.NomTarif = this.currentFamille.NomFamilleTarif;
      }
    },
    close() {
      this.$emit("onClose");
    },

    async submit() {
      if (!this.context.ID_Saison) {
        this.context.ID_Saison = this.selectedSaison.id;
      }

      let ID_FamilleTarif = this.currentTarif.familleTarif;
      if (typeof ID_FamilleTarif == "object") {
        ID_FamilleTarif = ID_FamilleTarif.id;
      }

      if (ID_FamilleTarif == 1) {
        this.EST_OptionAdhesion = true;
      }

      try {
        let payload = {
          ID_EtapeValidationTarif:
            this.currentTarif.ID_EtapeValidationTarif ?? 1,
          ID_Saison: this.context.ID_Saison,
          ID_TypeLicence: null,
          ID_TypeAssurance: null,
          ID_Structure: this.context.ID_Structure,
          MontantUnitaire: this.currentTarif.MontantUnitaire,
          NomTarif: this.currentTarif.NomTarif,
          Ordre: this.currentTarif.Ordre,
          ID_FamilleTarif: ID_FamilleTarif,
          EST_Objet: this.EST_OptionAdhesion,
          ID_TarifCategorie: this.tarifCategorie.find(
            (tarif) => tarif.SlugCategorieTarif == "tarif_club"
          ).id,
        };
        // console.log(payload)
        if (this.currentTarif.id) payload.id = this.currentTarif.id;
        let response = await this.updateAffiliationTarifSructure({
          tarif: payload,
        });
        console.log(payload);
        console.log(response);
        this.$emit("onUpdated", response);
        this.currentTarif = {};
      } catch (e) {
        console.log(e);
        error_notification("Oups, une erreur est survenue...");
      }
    },
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.option__adhesion .v-input__slot .v-label {
  margin-bottom: 0rem !important;
}
</style>
