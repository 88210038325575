<template>
  <div v-if="!mounted">Chargement ...</div>
  <div v-else style="width: 100%">
    <GestionSalaries
      :context="{
        hasCodeFederal,
        ID_Structure: currentStructureId,
        ID_Saison: selectedSaison.id,
      }"
    />

    <ValidateForm @validated="updateEquivalentTempsPlein">
      <div class="mt-4">
        <inputText
          type="number"
          step="0.1"
          v-model="SAL_EquivalentTempsPlein"
          label=" Nombre d'équivalents temps plein dans mon club/section de club"
          rules="required|max:3"
          :disabled="!hasCodeFederal"
        >
          <v-btn
            :disabled="!hasCodeFederal"
            type="submit"
            class="mx-2"
            fab
            dark
            x-small
            color="cyan"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
        </inputText>
        <span class="caption font-italic"
          >(exemple: 0.5 pour un salarié à mi-temps)</span
        >
      </div>
    </ValidateForm>

    <div class="mt-4">
      <selectInput
        label="Si la structure a des salariés, quel est l'organisme de la formation professionnelle"
        :items="allCollecteur"
        v-model="currentCollecteur"
        item_text="NomCollecteur"
        item_value="id"
        :disabled="!hasCodeFederal"
        @onChange="collecteurChange"
      ></selectInput>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import GestionSalaries from "@/components/Structure/GestionSalaries/Structure__GestionSalaries.vue";
import ValidateForm from "@/components/utils/form.vue";
import inputText from "@/components/utils/input.vue";
import selectInput from "@/components/utils/select.vue";

export default {
  components: { GestionSalaries, inputText, ValidateForm, selectInput },
  data: () => ({
    mounted: false,
    affiliationb: null,
    SAL_EquivalentTempsPlein: "",
    allCollecteur: [],
    structure: null,
    currentCollecteur: null,
  }),

  async created() {
    this.$emit("updateTitle", "Gestion des salariés");
    await this.getListTypesDocument();
    await this.getListCollecteurFormationProfessionnelle();

    this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", [
      "currentStructureId",
      "currentStructure",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["selectedSaison"]),
  },

  methods: {
    ...mapActions("basics", [
      "getListTypesDocument",
      "getListCollecteurFormationProfessionnelle",
    ]),
    ...mapActions("structure", [
      "getStructureAffiliation",
      "updateAffiliationETPStructure",
      "updateAffiliationCollecteurFormationPro",
    ]),

    async setData() {
      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSaison.id,
      });
      this.structure = { ...this.currentStructure };
      this.allCollecteur = this.getList.LIST_CollecteurFormationProfessionnelle;
      this.SAL_EquivalentTempsPlein = this.affiliation.SAL_EquivalentTempsPlein;

      if (this.affiliation.ID_CollecteurFormationProfessionnelle) {
        this.currentCollecteur = this.allCollecteur.find(
          (c) => c.id == this.affiliation.ID_CollecteurFormationProfessionnelle
        );
      }
    },

    async updateEquivalentTempsPlein() {
      try {
        await this.updateAffiliationETPStructure({
          ID_Structure: this.currentStructureId,
          ID_Saison: this.selectedSaison.id,
          SAL_EquivalentTempsPlein: this.SAL_EquivalentTempsPlein,
        });
        success_notification("Mis à jour");
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
        console.log(e);
      }
    },

    async collecteurChange(collecteur) {
      let ID_Collecteur = this.allCollecteur.find((c) => c.id == collecteur).id;
      try {
        await this.updateAffiliationCollecteurFormationPro({
          ID_Structure: this.currentStructureId,
          ID_Saison: this.selectedSaison.id,
          ID_CollecteurFormationProfessionnelle: ID_Collecteur,
        });
        success_notification("Organisme de formation mis à jour");
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
        console.log(e);
      }
    },
  },
};
</script>
