<template>
  <v-dialog
    v-model="isOpen"
    @click:outside="$emit('onClose')"
    persistent
    max-width="800px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab style="display:flex;flex-direction:column;">
        <span style="font-size:1.2rem;">
          Historique - {{ documentTypeLabel }}
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="$emit('onClose')">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content">
      <v-tabs-items>
        <v-row class="p-4">
          <v-col v-if="!mounted" class="text-center">
            <v-progress-circular
              indeterminate
              color="indigo darken-4"
            ></v-progress-circular>
          </v-col>

          <v-col v-else class="item-list">
            <v-row
              no-gutters
              v-for="(document, index) in documents"
              :key="index"
              class="history-item"
            >
              <ItemHistory :context="context" :document="document" :fileType="fileType" />
            </v-row>
          </v-col>
        </v-row>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ItemHistory from "@/components/utils/modals/Documents/Document__HistoryNewItem.vue";
import { getStructureDocumentsByStructureId } from "../../../../plugins/structureService";
import { 
  structureDocumentTypeLabel,
  structureDocumentTypeToDocDocumentId,
} from "@/constants/structureDocumentType";

export default {
  components: { ItemHistory },

  data: () => ({
    documents: [],
    mounted: false,
    modalPreviewIsOpen: false,
  }),

  async created() {
    const structureDocuments = await getStructureDocumentsByStructureId(this.context.ID_Structure, [this.context.documentType], { displayAll: true });
    const structureDocumentsData = structureDocuments;
    this.documents = structureDocumentsData;
    
    let docDocuments = [];

    for (const element of structureDocumentTypeToDocDocumentId[this.context.documentType]) {
      const docDocument = await this.getDocumentStructureByType({
        ID_Structure: this.context.ID_Structure,
        ID_DocumentType: element,
      });
      
      docDocuments.push(docDocument);
    }

    docDocuments = docDocuments.filter(doc => doc.length !== 0);
    docDocuments = docDocuments.flat(Infinity);

    const finalDocs = [];

    for (const docDocument of docDocuments) {
      finalDocs.push({
        data: docDocument,
        isNewDoc: false,
      });
    }

    for (const structureDocument of structureDocumentsData) {
      finalDocs.push({
        data: structureDocument,
        isNewDoc: true,
      });
    }

    finalDocs.sort((a, b) => {
      const dateA = a.isNewDoc ? new Date(a.data.createdAt) : new Date(a.data.Z_DateCreation);
      const dateB = b.isNewDoc ? new Date(b.data.createdAt) : new Date(b.data.Z_DateCreation);
      
      return dateB - dateA;
    });

    this.documents = finalDocs;
    this.mounted = true;
  },

  computed: {
    documentTypeLabel() {
      return structureDocumentTypeLabel[this.context.documentType];
    },
  },

  methods: {
    ...mapActions("structure", ["getDocumentStructureByType"]),
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
    },
    fileType: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.modal__content {
  overflow-x: hidden;
}
</style>
<style scoped>
.item-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
