<template>
  <div  style="width:100%">
    <DocumentAJoindre
      :context="{
        ID_Structure: currentStructureId,
        ID_Saison: selectedSaison.id,
        hasCodeFederal
      }"
    />
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import documentAffiliation from "@/components/Affiliations/Affiliation_StructureDocUpload.vue";
import DocumentAJoindre from "@/components/GestionDesDocuments/GestionDesDocuments.vue"
 
export default {

  components: { DocumentAJoindre },

  data: () => ({
    mounted: false,
  }),

  async created() {
  
    this.mounted = true;
    return
  },

  computed: {

    ...mapGetters("structure", [ "currentStructureId", "hasCodeFederal" ]),
    ...mapGetters("saisons", ["selectedSaison"]),

   
  },

  methods: {

  },
};
</script>
