<template>
  <v-dialog
    v-if="openModal"
    v-model="openModal"
    @click:outside="close()"
    max-width="600px"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display:flex;flex-direction:column;">
        <span style="font-size:1.2rem;">
          Modifier un type de tarif
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat class="px-10 py-6">
          <ValidateForm
            @validated="submit"
            :loading="loading"
            style="width: 100%;"
            :showFieldObl="false"
          >
            <v-col cols="12">
              <InputSelect
                v-model="familleTarif"
                :items="allTarifFamille"
                label="Type tarif"
                item_text="NomFamilleTarif"
                item_value="id"
                rules="required"
              />
            </v-col>
            <v-col cols="12">
              <InputText
                type="text"
                v-model="tarif"
                rules="required"
                label="Nouveau libellé"
                placeholder="Saisissez le nouveau libellé du type tarif"
              ></InputText>
            </v-col>

            <v-card-actions class="">
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :loading="loading"
                rounded
                :disabled="familleTarif === '' || tarif === ''"
                color="blue-dark text-white font-weight-bold"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </ValidateForm>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";
import InputSelect from "@/components/utils/select.vue";

import { mapActions } from "vuex";
import { error_notification } from "@/plugins/notifications";
import { string_to_slug } from "@/plugins/utils";

export default {
  components: { ValidateForm, InputText, InputSelect },

  data: () => ({
    loading: false,
    mounted: false,
    tarif: "",
    tarifCategorie: [],
    familleTarif: "",
  }),

  async created() {
    await this.setData();
    this.mounted = true;
  },

  methods: {
    ...mapActions("structure", ["getAllFamilleTarif"]),
    ...mapActions("basics", ["getListTarifCategorie", "insertNewFamilleTarif"]),

    async setData() {
      this.tarifCategorie = await this.getListTarifCategorie();
      return;
    },

    close() {
      this.$emit("onClose");
    },

    async submit() {
      try {
        let payload = {
          id: this.familleTarif,
          ID_Structure: this.context.ID_Structure,
          NomFamilleTarif: this.tarif,
          SlugFamilleTarif: string_to_slug(this.tarif),
          ID_TarifCategorie: this.tarifCategorie.find(
            (tar) => tar.SlugCategorieTarif == "licence_club"
          ).id,
        };
        await this.insertNewFamilleTarif({ tarif: payload });
        this.$emit("on-modify");
      } catch (e) {
        console.log(e);
        error_notification("Oups, une erreur est survenue...");
      }
    },
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
    },
    allTarifFamille: {
      type: Array,
    },
  },
};
</script>
