<template>
    <div style="width: 100%">
        <v-row>
            <v-col cols="12" md="6" class="py-0" style="display: flex; align-items: center;">
                    <div class="px-2 py-2">
                        <span class="font-weight-bold" style="font-size: .9rem">
                            {{ documentName }}
                        </span>
                    </div>
            </v-col>
            <v-col cols="12" md="3" class="py-0" style="display: flex; align-items: center;">
                    <div class="px-2 py-2">
                        <span class="font-weight-bold" style="font-size: .9rem">
                            {{ documentCreationDate }}
                        </span>
                    </div>
            </v-col>
            <v-col  cols="12" md="3" class="text-right" v-if="document.isNewDoc">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            class="ml-2"
                            @click="openPreview"
                            fab
                            dark
                            x-small
                            outlined
                            :color="isDocumentPreviewable ? 'indigo darken-4' : 'grey lighten-1'"
                        >
                        <v-icon>mdi-image</v-icon>
                        </v-btn>
                    </template>
                    <span>Afficher le document</span>
                </v-tooltip>
            </v-col>

            <v-col cols="12" md="3" class="text-right" v-else>
                    <v-btn
                        class="ml-2"
                        @click="modalPreviewIsOpen = true"
                        fab
                        dark
                        x-small
                        outlined
                        :color="isDocumentPreviewable ? 'indigo darken-4' : 'grey lighten-1'"
                    >
                    <v-icon color="indigo darken-4">mdi-image</v-icon>
                    <modalPreviewDocument
                        v-if="modalPreviewIsOpen"
                        @onClose="modalPreviewIsOpen = false"
                        :isOpen="modalPreviewIsOpen"
                        :context="{...context, document: document.data }"
                    ></modalPreviewDocument>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import modalPreviewDocument from "@/components/utils/modals/Documents/Document__PreviewDocument.vue"

export default {
    components: { modalPreviewDocument },

    data: () => ({
        modalPreviewIsOpen: false
    }),

    methods: {
        openPreview() {
            if (!this.isDocumentPreviewable) {
                return false;
            }

            window.open(process.env.VUE_APP_FFME_BACK_URL + '/api/files/' + this.fileType + '/' + this.document.data.id);

            return null;
        },
    },

    computed: {
        documentName() {
            if (this.document.isNewDoc) {
                return this.document.data.file.originalName;
            }

            return this.document.data.NomDocument;
        },

        documentCreationDate() {
            if (this.document.isNewDoc) {
                return this.$moment(this.document.data.createdAt).format("DD/MM/YYYY");
            }

            return this.$moment(this.document.data.Z_DateCreation).format("DD/MM/YYYY");
        },

        isDocumentPreviewable() {
            if (this.document.isNewDoc) {
                return !!this.document.data.file;
            }

            return !!this.document.data.CheminDocument;
        },
    },

    props: {
        context: {
            type: Object,
        },
        document: {
            type: Object,
        },
        fileType: {
            type: String,
            default: null,
        }
    }
}
</script>
