<template>
  <section class="list-adress">
    <v-data-table
      :headers="headers"
      :items="adresses"
    >
      <template v-slot:item.select="{item}" >
        <v-icon v-if=" !adress || item.id !== adress.id" @click="selectAdress(item)" color="green">mdi-check</v-icon>
      </template>

      <template v-slot:item.update="{item}" >
        <v-btn icon color="primary" outlined @click.prevent="goToEdit(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: ()=>({
    headers: [
      { text: "Choisir", value: 'select' , sortable: false },
      { text: "Nom de l'adresse", value: "NomAdresse" },
      { text: "Adresse", value: "Adresse1" },
      { text: "Code Postal", value: "CodePostal" },
      { text: "Ville", value: "Ville" },
      { text: "Modifier", value: 'update', sortable: false },

    ],
    adresses: []
  }),
  async created() {
    await this.setData()
    return
    //console.log('adererer', this.adress);
  },
  methods: {
    ...mapActions("structure", [
      "insertStructureAddress",
      "getStructureAdresses"
    ]),
    async setData() {
      this.adresses = await this.getStructureAdresses(this.context);
      return;
    },
    selectAdress(adress) {
      this.$emit("onSelected", adress)
    },
    goToEdit(adress) {
      this.$emit('onEdit', adress)
    }
  },
   props: {
    adress: {
      type: Object,
      default: null
    },

    context: {
      type: Object
    }
  }
}
</script>

<style>

</style>