<template>
    <div style="width: 100%">
        <v-row>
            <v-col cols="12" md="6" class="py-0" style="display: flex; align-items: center;">
                        <div class="px-2 py-2">
                        <span class="font-weight-bold" style="font-size: .9rem">
                            {{ document.NomDocument }}
                        </span>
                    </div>
            </v-col>
            <v-col cols="12" md="3" class="py-0" style="display: flex; align-items: center;">
                    <div class="px-2 py-2">
                        <span class="font-weight-bold" style="font-size: .9rem">
                            {{ $moment(document.Z_DateCreation).format("DD/MM/YYYY") }}
                        </span>
                    </div>
            </v-col>
            <v-col  cols="12" md="3" class="text-right">

                <v-btn  @click="modalPreviewIsOpen = true" class="ml-2" fab
                            dark
                            x-small
                            outlined
                            :color="isDocumentPreviewable ? 'indigo darken-4' : 'grey lighten-1'" >
                    <v-icon>mdi-image</v-icon>
                        <modalPreviewDocument
                            @onClose="modalPreviewIsOpen = false"
                            :isOpen="modalPreviewIsOpen"
                            v-if="modalPreviewIsOpen"
                            :context="{...context, document: document }"
                        ></modalPreviewDocument>
                    
                </v-btn>

            </v-col>
        </v-row>
    </div>

</template>

<script>
import modalPreviewDocument from "@/components/utils/modals/Documents/Document__PreviewDocument.vue"

export default {
    
    components: {modalPreviewDocument},

    data: () => ({
        modalPreviewIsOpen: false
    }),

    computed: {
        isDocumentPreviewable() {
            return !!this.document.CheminDocument;
        },
    },

    props: {
        context: {
            type: Object
        },
        document: {
            type: Object
        }
    }
}
</script>
